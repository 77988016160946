import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const fetchData = async (setProcessedData) => {
    // 生成两个80以内的随机整数组
    const location_ids = [
        Math.floor(Math.random() * 80) + 1, 
        Math.floor(Math.random() * 80) + 1
    ];

    // 获取当前时间
    const now = new Date();
    const current_time = now.getFullYear() + '-' +
                         String(now.getMonth() + 1).padStart(2, '0') + '-' +
                         String(now.getDate()).padStart(2, '0') + 'T' +
                         String(now.getHours()).padStart(2, '0') + ':' +
                         String(now.getMinutes()).padStart(2, '0') + ':' +
                         String(now.getSeconds()).padStart(2, '0');

    // 生成120到220之间的随机整数
    const current_flow = Math.floor(Math.random() * (220 - 120 + 1)) + 120;

    const data = {
        "current_time": current_time,
        "current_flow": current_flow,
        "location_ids":location_ids,
        "prediction_mode": "hourly"
    };
    console.log(data);


    try {
        const response = await fetch('http://54.252.199.85:8080/predict', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        // 获取 hourly_predictions 对象
        const predictions = result.hourly_predictions;

        // 将每个时间段的 predicted_flow 值累加
        const combinedData = Array.from({ length: 24 }, (_, i) => {
            const sumFlow = Object.keys(predictions).reduce((sum, locId) => {
                return sum + predictions[locId][i].predicted_flow;
            }, 0);

            return {
                time: i,
                value: sumFlow,
            };
        });

        // 更新状态
        setProcessedData(combinedData);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

const LineChart = () => {
  const ref = useRef();
  const [tooltipData, setTooltipData] = useState({ time: null, value: null, x: 0, y: 0 });
  const [processedData, setProcessedData] = useState([]);

  useEffect(() => {
    fetchData(setProcessedData); // fetchData 并传入处理后的数据
}, []);

  useEffect(() => {

    if (processedData.length === 0) return;

    d3.select(ref.current).selectAll("*").remove();

    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", 550)
      .attr("height", 300);

    const margin = { top: 20, right: 30, bottom: 50, left: 50 };
    const width = +svg.attr("width") - margin.left - margin.right;
    const height = +svg.attr("height") - margin.top - margin.bottom;

    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);
  
    const now = new Date();
    const currentHour = now.getHours();

    const x = d3.scaleLinear().domain([0, 23]).range([0, width]);
    const y = d3.scaleLinear().domain([0, d3.max(processedData, d => d.value)]).range([height, 0]);

    // 定义渐变色
    const pastGradient = svg.append("defs").append("linearGradient").attr("id", "pastGradient")
      .attr("x1", "0%").attr("x2", "0%").attr("y1", "100%").attr("y2", "0%");
    
    pastGradient.append("stop").attr("offset", "0%").attr("style", "stop-color:#2f78c2;stop-opacity:1");
    pastGradient.append("stop").attr("offset", "100%").attr("style", "stop-color:#72b2d4;stop-opacity:1");

    const futureGradient = svg.append("defs").append("linearGradient").attr("id", "futureGradient")
      .attr("x1", "0%").attr("x2", "0%").attr("y1", "100%").attr("y2", "0%");

    futureGradient.append("stop").attr("offset", "0%").attr("style", "stop-color:#72b2d4;stop-opacity:1");
    futureGradient.append("stop").attr("offset", "100%").attr("style", "stop-color:#a5d396;stop-opacity:1");

    // 绘制过去的数据
    g.append("path")
      .datum(processedData.filter(d => d.time <= currentHour))
      .attr("fill", "url(#pastGradient)")
      .attr("stroke", "#72b2d4")
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "6 4")
      .attr("d", d3.area().x(d => x(d.time)).y0(height).y1(d => y(d.value)).curve(d3.curveMonotoneX));

    const lastPastPoint = processedData[currentHour];

    // 绘制未来的数据
    g.append("path")
      .datum(processedData.filter(d => d.time >= currentHour))
      .attr("fill", "url(#futureGradient)")
      .attr("stroke", "#a5d396")
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "6 4")
      .attr("d", d3.area().x(d => x(d.time)).y0(height).y1((d, i) => i === 0 ? y(lastPastPoint.value) : y(d.value)).curve(d3.curveMonotoneX));

    const xAxis = g.append("g").attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x).ticks(24)).selectAll("text").attr("fill", "black");

    const yAxis = g.append("g").call(d3.axisLeft(y)).selectAll("text").attr("fill", "black");

    svg.append("text").attr("x", width / 2 + margin.left).attr("y", height + margin.top + 35)
      .attr("text-anchor", "middle").attr("fill", "black").text("Time");

    svg.append("text").attr("transform", "rotate(-90)").attr("x", -height / 2 - margin.top)
      .attr("y", margin.left / 2 - 10).attr("text-anchor", "middle").attr("fill", "black")
      .text("Pedestrian Flow");

    g.append("circle").attr("cx", x(currentHour)).attr("cy", y(processedData.find(d => d.time === currentHour).value))
      .attr("r", 5).attr("fill", "#000").attr("stroke", "#fff").attr("stroke-width", 2);

    const indicatorLine = g.append("line").attr("class", "dashed-line").attr("x1", 0).attr("x2", 0)
      .attr("y1", height).attr("y2", height).style("opacity", 0);

    const circleIndicator = g.append("circle").attr("class", "circle-indicator").attr("r", 5)
      .style("opacity", 0);

    // 监听鼠标移动事件，更新tooltip
    svg.on("mousemove", function (event) {
      const mouseX = d3.pointer(event, this)[0] - margin.left;
      const mouseTime = Math.round(x.invert(mouseX));

      if (mouseTime >= 0 && mouseTime <= 23) {
        const mouseData = processedData.find(d => d.time === mouseTime);
        if (mouseData) {
          setTooltipData({
            time: mouseData.time,
            value: mouseData.value.toFixed(2),
            x: event.pageX,
            y: event.pageY - 30
          });

          indicatorLine.attr("x1", x(mouseData.time)).attr("x2", x(mouseData.time))
            .attr("y1", height).attr("y2", y(mouseData.value)).style("opacity", 1);

          circleIndicator.attr("cx", x(mouseData.time)).attr("cy", y(mouseData.value)).style("opacity", 1);
        }
      } else {
        setTooltipData({ time: null, value: null, x: 0, y: 0 });
        indicatorLine.style("opacity", 0);
        circleIndicator.style("opacity", 0);
      }
    });

    svg.on("mouseout", function () {
      setTooltipData({ time: null, value: null, x: 0, y: 0 });
      indicatorLine.style("opacity", 0);
      circleIndicator.style("opacity", 0);
    });
  }, [processedData]); // 加入 processedData 依赖

  return (
    <div ref={ref}>
      {tooltipData.time !== null && (
        <div
          className="tooltip"
          style={{
            position: "absolute",
            left: `${tooltipData.x}px`,
            top: `${tooltipData.y}px`,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "#fff",
            padding: "5px",
            borderRadius: "3px",
            pointerEvents: "none"
          }}
        >
          Time: {tooltipData.time}<br />
          Value: {tooltipData.value}
        </div>
      )}
    </div>
  );
};

export default LineChart;
