import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom'; // <-- Import useNavigate

// Keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideInUp = keyframes`
  from { transform: translateY(100px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

// Styled components for layout and sections
const Container = styled.div`
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f5f4e2;
  width: 100vw;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Section = styled.section`
  width: 80vw;
  max-width: 800px;
  padding: 50px 20px;
  margin: 20px 0;
  background-color: #e6deb8;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: ${fadeIn} 1s ease forwards;
`;

const SectionTitle = styled.h3`
  font-size: 28px;
  color: #417446;
`;

const Question = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
`;

const OptionGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OptionButton = styled.button`
  font-size: 18px;
  background-color: #f0ebd4;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #ede7cd;
    transform: scale(1.05);
  }

  &.active {
    background-color: #598f57;
    color: white;
  }
`;

const HiddenCard = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: ${slideInUp} 1s ease;
`;

const SubmitButton = styled.button`
  padding: 15px 30px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: #598f57;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #417446;
    transform: scale(1.05);
  }
`;

const ResultSection = styled.section`
  padding: 20px;
  background-color: #f5f4e2;
  color: #333;
  text-align: center;
`;

const ResultTitle = styled.h4`
  font-size: 24px;
  margin-bottom: 20px;
  color: #417446;
`;

const ResultCard = styled.div`
  padding: 20px;
  background-color: #e6deb8;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

function AssessmentPage() {
  const [step, setStep] = useState(1);
  const [showCard1, setShowCard1] = useState(false);
  const [showCard2, setShowCard2] = useState(false);
  const [showCard3, setShowCard3] = useState(false);
  const [score, setScore] = useState(0);
  const navigate = useNavigate(); // <-- Initialize the useNavigate hook

  // Add delay logic to show fun fact before moving to the next step
  const delayNextStep = (nextStep) => {
    setTimeout(() => {
      setStep(nextStep);
    }, 3000); // 3 seconds delay
  };

  const handleAnswer1 = (value) => {
    setShowCard1(true); // <-- Ensure card visibility is set to true
    setScore((prevScore) => prevScore + value); // <-- Update score
    delayNextStep(2); // <-- Proceed to the next step after delay
  };

  const handleAnswer2 = (value) => {
    setShowCard2(true); // <-- Ensure card visibility is set to true
    setScore((prevScore) => prevScore + value); // <-- Update score
    delayNextStep(3); // <-- Proceed to the next step after delay
  };

  const handleAnswer3 = (value) => {
    setShowCard3(true); // <-- Ensure card visibility is set to true
    setScore((prevScore) => prevScore + value); // <-- Update score
    delayNextStep(4); // <-- Proceed to the result after delay
  };

  const handleSubmit = () => {
    navigate('/nearby-green-spaces'); // <-- Navigate to the Nearby Green Spaces page
  };

  return (
    <Container>
      {/* Question 1 */}
      {step === 1 && (
        <Section>
          <SectionTitle>Assessment: How Green Are You?</SectionTitle>
          <Question>1. How many hours of free time do you spend indoors each day?</Question>
          <OptionGroup>
            <OptionButton onClick={() => handleAnswer1(4)}>0-2 hours</OptionButton>
            <OptionButton onClick={() => handleAnswer1(3)}>3-5 hours</OptionButton>
            <OptionButton onClick={() => handleAnswer1(2)}>6-8 hours</OptionButton>
            <OptionButton onClick={() => handleAnswer1(1)}>9+ hours</OptionButton>
          </OptionGroup>

          {showCard1 && ( // <-- Only show if showCard1 is true
            <HiddenCard>
              Did you know? Spending time outdoors instead of indoors can save up to 20% of energy consumption daily!
            </HiddenCard>
          )}
        </Section>
      )}

      {/* Question 2 */}
      {step === 2 && (
        <Section>
          <Question>2. How many trips do you take to parks or green spaces each week by car?</Question>
          <OptionGroup>
            <OptionButton onClick={() => handleAnswer2(4)}>0-1 trips</OptionButton>
            <OptionButton onClick={() => handleAnswer2(3)}>2-3 trips</OptionButton>
            <OptionButton onClick={() => handleAnswer2(2)}>4-6 trips</OptionButton>
            <OptionButton onClick={() => handleAnswer2(1)}>7+ trips</OptionButton>
          </OptionGroup>

          {showCard2 && ( // <-- Only show if showCard2 is true
            <HiddenCard>
              Did you know? Driving for 2 km daily requires you to plant 7 trees a year to compensate for the CO2 emissions.
            </HiddenCard>
          )}
        </Section>
      )}

      {/* Question 3 */}
      {step === 3 && (
        <Section>
          <Question>3. Have you ever felt stress or your mind got blocked?</Question>
          <OptionGroup>
            <OptionButton onClick={() => handleAnswer3(2)}>Yes</OptionButton>
            <OptionButton onClick={() => handleAnswer3(2)}>No</OptionButton>
          </OptionGroup>

          {showCard3 && ( // <-- Only show if showCard3 is true
            <HiddenCard>
              Green spaces help clear your mind and reduce stress. It's not only good for physical health but also for mental well-being.
            </HiddenCard>
          )}
        </Section>
      )}

      {/* Result Section */}
      {step === 4 && (
        <ResultSection>
          <ResultTitle>Your Green Score</ResultTitle>
          <ResultCard>
            <p>Your green score is: {score}/10</p>
            <p>You are ranked as: {score > 7 ? 'Green Guardian' : score > 4 ? 'Eco Explorer' : 'Nature Newbie'}</p>
          </ResultCard>
          <SubmitButton onClick={handleSubmit}>Discover How GreenFinder Can Help You!</SubmitButton>
        </ResultSection>
      )}
    </Container>
  );
}

export default AssessmentPage;
