import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import polyline from '@mapbox/polyline';

mapboxgl.accessToken = 'pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q';

const NavigationMap = ({ userCoordinates, parkCoordinates, transportMode, routeOption, setIsScenicAvailable, setTurnByTurnDirections }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const directions = useRef(null);
    const [defaultRoute, setDefaultRoute] = useState(null);
    const [scenicRoute, setScenicRoute] = useState(null);
    const [mapInitialized, setMapInitialized] = useState(false);

    // Wrap melbourneBounds in useMemo to ensure it's not recreated on every render
    const melbourneBounds = useMemo(() => [
        [144.5937, -38.4339], // Southwest corner
        [145.5125, -37.5113]  // Northeast corner
    ], []);

    // Fetch routes for the selected mode of transport
    const fetchRoutesForMode = useCallback((mode, origin, destination) => {
        console.log(`Switching to ${mode} mode.`); // Log mode switch
        console.log(`Fetching routes from ${origin.latitude}, ${origin.longitude} to ${destination.latitude}, ${destination.longitude}`);

        if (!directions.current) {
            directions.current = new MapboxDirections({
                accessToken: mapboxgl.accessToken,
                unit: 'metric',
                profile: `mapbox/${mode}`,
                alternatives: true,
                controls: { instructions: false, inputs: false },
                interactive: false,
            });
        }

        directions.current.setOrigin([origin.longitude, origin.latitude]);
        directions.current.setDestination([destination.longitude, destination.latitude]);

        directions.current.on('route', (e) => {
            const routes = e.route;
            console.log('Fetched routes:', routes); // Log fetched routes

            if (routes.length > 0) {
                setDefaultRoute(routes[0]);
                console.log('Default route:', routes[0]); // Log default route

                // Set turn-by-turn instructions for the default route
                setTurnByTurnDirections(formatTurnByTurnInstructions(routes[0]));

                if (routes.length > 1) {
                    setScenicRoute(routes[1]);
                    setIsScenicAvailable(true);
                    console.log('Scenic route available:', routes[1]); // Log scenic route
                } else {
                    setScenicRoute(null);
                    setIsScenicAvailable(false);
                    console.log('No scenic route available'); // Log no scenic route
                }
            }
        });
    }, [setTurnByTurnDirections, setIsScenicAvailable]); // Ensure fetchRoutesForMode is memoized and stable

    // Format turn-by-turn instructions
    const formatTurnByTurnInstructions = (route) => {
        const legs = route.legs[0]; // Assumes only one leg
        return legs.steps.map((step) => ({
            instruction: step.maneuver.instruction,
            distance: step.distance,
            duration: step.duration,
        }));
    };

    // Draw the selected route on the map
    const drawRouteOnMap = (route, isScenic) => {
        if (!map.current || !route || !route.geometry) return;

        let coordinates = [];

        if (typeof route.geometry === 'string') {
            coordinates = polyline.decode(route.geometry).map(coord => [coord[1], coord[0]]);
        } else if (route.geometry.coordinates) {
            coordinates = route.geometry.coordinates;
        }

        const routeGeoJSON = {
            type: 'Feature',
            properties: {},
            geometry: { type: 'LineString', coordinates },
        };

        if (map.current.getSource('route')) {
            map.current.removeLayer('routeLayer');
            map.current.removeSource('route');
        }

        map.current.addSource('route', {
            type: 'geojson',
            data: routeGeoJSON,
        });

        map.current.addLayer({
            id: 'routeLayer',
            type: 'line',
            source: 'route',
            layout: { 'line-join': 'round', 'line-cap': 'round' },
            paint: {
                'line-color': isScenic ? '#007FFF' : '#388E3C', // Scenic: Orange, Default: Green
                'line-width': 10,
            },
        });

        const bounds = coordinates.reduce((bounds, coord) => bounds.extend(coord), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
        map.current.fitBounds(bounds, { padding: 50 });
    };

    useEffect(() => {
        if (mapInitialized) {
            if (routeOption === 'scenic' && scenicRoute) {
                console.log('Switching to scenic route.');
                drawRouteOnMap(scenicRoute, true);
                setTurnByTurnDirections(formatTurnByTurnInstructions(scenicRoute));
            } else if (defaultRoute) {
                console.log('Switching to default eco-friendly route.');
                drawRouteOnMap(defaultRoute, false);
                setTurnByTurnDirections(formatTurnByTurnInstructions(defaultRoute));
            }
        }
    }, [routeOption, scenicRoute, defaultRoute, mapInitialized, setTurnByTurnDirections]);

    useEffect(() => {
        if (!parkCoordinates || !userCoordinates) return;

        const parsedParkCoordinates = typeof parkCoordinates === 'string'
            ? {
                  latitude: parseFloat(parkCoordinates.replace(/[()]/g, '').split(',')[0]),
                  longitude: parseFloat(parkCoordinates.replace(/[()]/g, '').split(',')[1]),
              }
            : parkCoordinates;

        if (!map.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [userCoordinates.longitude, userCoordinates.latitude],
                zoom: 12,
                maxBounds: melbourneBounds,
            });

            map.current.on('load', () => {
                setMapInitialized(true);

                // Add 3D terrain and buildings
                map.current.addSource('mapbox-dem', {
                    type: 'raster-dem',
                    url: 'mapbox://mapbox.terrain-rgb',
                    tileSize: 512,
                    maxzoom: 14,
                });

                map.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });

                // Add 3D buildings layer
                map.current.addLayer({
                    id: '3d-buildings',
                    source: 'composite',
                    'source-layer': 'building',
                    filter: ['==', 'extrude', 'true'],
                    type: 'fill-extrusion',
                    minzoom: 15,
                    paint: {
                        'fill-extrusion-color': '#aaa',
                        'fill-extrusion-height': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            16.05,
                            ['get', 'height'],
                        ],
                        'fill-extrusion-base': [
                            'interpolate',
                            ['linear'],
                            ['zoom'],
                            15,
                            0,
                            16.05,
                            ['get', 'min_height'],
                        ],
                        'fill-extrusion-opacity': 0.6,
                    },
                });

                // Add markers for user and park locations
                new mapboxgl.Marker({ color: 'red' })
                    .setLngLat([userCoordinates.longitude, userCoordinates.latitude])
                    .addTo(map.current);

                new mapboxgl.Marker({ color: 'green' })
                    .setLngLat([parsedParkCoordinates.longitude, parsedParkCoordinates.latitude])
                    .addTo(map.current);

                if (transportMode) {
                    fetchRoutesForMode(transportMode, userCoordinates, parsedParkCoordinates);
                }
            });
        } else if (transportMode) {
            fetchRoutesForMode(transportMode, userCoordinates, parsedParkCoordinates);
        }

        return () => {
            if (map.current && directions.current) {
                directions.current.removeRoutes();
                if (map.current.hasControl(directions.current)) {
                    map.current.removeControl(directions.current);
                }
                map.current.remove();
                map.current = null;
                directions.current = null;
            }
        };
    }, [userCoordinates, parkCoordinates, transportMode, fetchRoutesForMode, melbourneBounds]);

    return <div ref={mapContainer} style={{ width: '100%', height: '550px', borderRadius: '12px' }} />;
};

export default NavigationMap;
