import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Tabs, Tab,Slider } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';  // Import mapbox-gl for map functionality
import ExploreMap from './ExploreMap';  // Import the ExploreMap component
import ParkIcon from '@mui/icons-material/Park';  // Icon for park
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';  // For API calls

// Import icons for the amenities (replace with the actual paths or icon components)
import toiletIcon from '../../assets/exploreIcon/toilet.png';
import barbecueIcon from '../../assets/exploreIcon/barbecue.png';
import bicycleRailsIcon from '../../assets/exploreIcon/bicycle_rails.png';
import binIcon from '../../assets/exploreIcon/bin.png';
import drinkingFountainIcon from '../../assets/exploreIcon/drinking_fountain.png';
import picnicSettingIcon from '../../assets/exploreIcon/picnic_setting.png';
import playgroundIcon from '../../assets/exploreIcon/playground.png';
import seatIcon from '../../assets/exploreIcon/seat.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';  // Check icon
import CancelIcon from '@mui/icons-material/Cancel';  // Cross icon



const ExploreParkPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { park, placeId, category } = location.state || {};  // Extract all passed data

  // Define parkCoordinates first before using it
  const parkCoordinates = park?.coords
    ? {
        latitude: parseFloat(park.coords.split(',')[0].replace(/[()]/g, '')),
        longitude: parseFloat(park.coords.split(',')[1].replace(/[()]/g, '')),
      }
    : null;

  const [mapCenter] = useState([parkCoordinates?.longitude, parkCoordinates?.latitude]);  // Initial map center
  const [mapZoom] = useState(12);  // Initial zoom level    

  const [selectedTab, setSelectedTab] = useState(0);  // Tab state
  const [amenities, setAmenities] = useState({});  // Store amenities data
  const [selectedAmenity, setSelectedAmenity] = useState(null);  // Store the selected amenity
  const [directions, setDirections] = useState({ route: null, steps: [] });  // Store turn-by-turn directions and route
  const [recommendations, setRecommendations] = useState([]);  // Store filtered recommendations

  const [crowdingData, setCrowdingData] = useState([]);
  const [selectedHour, setSelectedHour] = useState(0); // Slider's current value


  const mapboxToken = 'pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q';  // Replace with your Mapbox token

  // Fetch amenities data when the component mounts
  useEffect(() => {
    if (park && placeId && category) {
      axios.get(`https://api.greenfinderinmelb.me/api/locate-amenities?place_id=${placeId}&category="${category}"`)
        .then(response => {
          setAmenities(response.data);  // Store the fetched amenities
        })
        .catch(error => {
          console.error('Error fetching amenities:', error);
        });
    }
  }, [park, placeId, category]);

  const amenitiesLegend = [
    { name: 'Toilets', icon: toiletIcon },
    { name: 'Barbecues', icon: barbecueIcon },
    { name: 'Bicycle Rails', icon: bicycleRailsIcon },
    { name: 'Litter Bin', icon: binIcon },
    { name: 'Drinking Fountain', icon: drinkingFountainIcon },
    { name: 'Picnic Setting', icon: picnicSettingIcon },
    { name: 'Playgrounds', icon: playgroundIcon },
    { name: 'Seats', icon: seatIcon },
  ];

  // Function to normalize string for comparison
const normalizeString = (str) => str.toLowerCase().replace(/s$/, '');

  // Retrieve recommendations from sessionStorage and filter them based on crowding_level and area
  useEffect(() => {
    const savedRecommendations = JSON.parse(sessionStorage.getItem('top10GreenSpaces')) || [];
    
    if (savedRecommendations.length > 0 && park) {
      const filteredRecommendations = savedRecommendations
        .filter(
          (space) => 
            space.id !== park.id && 
            (space.crowding_level === 'Low' || 
            (space.crowding_level === 'Medium' && park.crowding_level !== 'Low') || 
            (space.crowding_level === 'High' && park.crowding_level === 'High'))
        )
        .sort((a, b) => a.area - b.area)  // Sort by the smallest area
        .slice(0, 3);  // Get top 3 suggestions

      setRecommendations(filteredRecommendations);
    }
  }, [park]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleAmenityClick = (amenity) => {
    setDirections({ route: null, steps: [] });  // Clear previous directions
    setSelectedAmenity({
      ...amenity, 
      type: Object.keys(amenities).find(key => amenities[key].includes(amenity))  // Find the amenity type
    });  // Store the selected amenity with its type
    setSelectedTab(1);  // Switch to the second tab
  };

  const handleStartNavigate = () => {
    if (!selectedAmenity || !parkCoordinates) return;

    // Fetch directions from Mapbox Directions API
    const directionsUrl = `https://api.mapbox.com/directions/v5/mapbox/walking/${parkCoordinates.longitude},${parkCoordinates.latitude};${selectedAmenity.lon},${selectedAmenity.lat}?steps=true&geometries=geojson&access_token=${mapboxToken}`;

    axios.get(directionsUrl)
        .then(response => {
          const route = response.data.routes[0].geometry;
          const steps = response.data.routes[0].legs[0].steps;

          setDirections({ route, steps });

          const coordinates = route.coordinates;
          const bounds = coordinates.reduce((bounds, coord) => {
            return bounds.extend(coord);
          }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

          const map = new mapboxgl.Map({
            container: 'map', // Assuming 'map' is your container ID
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [parkCoordinates.longitude, parkCoordinates.latitude],
            zoom: 12,
          });

          // Fit the map to the route
          map.fitBounds(bounds, { padding: 20 });
        })
        .catch(error => {
          console.error('Error fetching directions:', error);
        });

  };

  const [parkInfo, setParkInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchParkInfo = async () => {
      try {
        const response = await axios.get(`https://api.greenfinderinmelb.me/api/place-info?place_id=${placeId}&category=${category}`);
        setParkInfo(response.data);
      } catch (err) {
        setError('Error fetching park info');
      } finally {
        setLoading(false);
      }
    };

    fetchParkInfo();
  }, [placeId, category]);

   // Call the API when the component mounts
   useEffect(() => {
    // Function to make the API call
    const fetchCrowdingData = async () => {
      // Current time calculation
      const now = new Date();
      const current_time = now.getFullYear() + '-' +
                           String(now.getMonth() + 1).padStart(2, '0') + '-' +
                           String(now.getDate()).padStart(2, '0') + 'T' +
                           String(now.getHours()).padStart(2, '0') + ':' +
                           String(now.getMinutes()).padStart(2, '0') + ':' +
                           String(now.getSeconds()).padStart(2, '0');

      const data = {
        "place_id": placeId,
        "current_time": current_time
      };

      try {
        const response = await axios.post('https://api.greenfinderinmelb.me/api/predict-crowding-level/', data);
        if (response.data.status === "success") {
          setCrowdingData(response.data.predictions);
          console.log('Fetching crowding data...:', response.data.predictions);
        }
      } catch (error) {
        console.error("Error fetching crowding data", error);
      }
    };
    fetchCrowdingData();

    
  }, [placeId]);


  // Handle slider value change
  const handleSliderChange = (event, newValue) => {
    setSelectedHour(newValue);
  };

  // Function to render emoji based on crowding level
  const renderEmoji = (crowdingLevel) => {
    const emojiStyle = { 
      fontSize: '3rem',  // Adjust the font size
      margin: 0,         // Remove margin to reduce blank space
      lineHeight: 1.5,
      display: 'flex',   // Use flexbox for centering
      justifyContent: 'center',  // Center horizontally
      alignItems: 'center',      // Center vertically
      height: '100%',       // Set line-height to 1 to prevent extra vertical space
    };
  
    switch (crowdingLevel) {
      case 'Low':
        return <p style={emojiStyle}>&#x1F603;</p>; // Smiley Face
      case 'Medium':
        return <p style={emojiStyle}>&#x1F610;</p>; // Neutral Face
      case 'High':
        return <p style={emojiStyle}>&#x1F622;</p>; // Crying Face
      default:
        return <p style={emojiStyle}>&#x1F636;</p>; // Blank Face
    }
  };
  

  // Get the crowding level for the selected hour
  const crowdingLevelForSelectedHour = crowdingData[selectedHour]
    ? crowdingData[selectedHour].crowding_level
    : 'Unknown';

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>{error}</Typography>;
  }

  return (

    
    <Box p={3} sx={{ maxWidth: '1400px', margin: '0 auto' }}>
      {/* Title Section */}
      <Box
        sx={{
          background: '#598f57', 
          padding: '15px 20px',  
          borderRadius: '15px',
          textAlign: 'center',
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)',  
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          position: 'relative',
          marginTop: '5px',  
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: '#ffffff',
            fontWeight: 'bold',
            letterSpacing: '1.5px',
            textShadow: '2px 2px 5px rgba(0,0,0,0.3)',
            mb: 1,
            fontSize: '2rem', 
          }}
        >
          Let’s Explore {park.name}
        </Typography>
      </Box>

      {/* Content Section */}
      <Box display="flex" flexDirection="row" gap={2} justifyContent="center" alignItems="start" mt={3}>
        <Box
          flex={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ 
            height: '500px',  
            borderRadius: '10px', 
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
            overflow: 'hidden',  
          }}
        >
          <ExploreMap 
            parkCoordinates={parkCoordinates} 
            amenities={amenities} 
            parkName={park.name}
            onAmenityClick={handleAmenityClick}  // Handle amenity click
            directions={directions.route}  // Pass the route to the map
            mapCenter={mapCenter}  // Pass map center to ExploreMap
            mapZoom={mapZoom}  // Pass map zoom level to ExploreMap
          />
        </Box>
        {/* Right Side */}
        {/* Legend Section */}
        
        <Box
          id="scrollable-right-column"
          flex={4}
          sx={{ 
            padding: 0, 
            backgroundColor: '#f1f8e9',  
            borderRadius: '12px', 
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
            height: '500px',  
            overflowY: 'auto',  
            border: '1px solid #66bb6a',  
            position: 'relative',  
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              position: 'sticky',  
              top: 0,  
              backgroundColor: '#f1f8e9',  
              zIndex: 1,  
              borderBottom: '2px solid #66bb6a',  
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',  
            }}
          >
            <Tab 
              label="Get to know this place" 
              icon={<InfoIcon sx={{ fontSize: '1.5rem', color: selectedTab === 0 ? '#1B5E20' : '#555' }} />}
              sx={{
                fontWeight: selectedTab === 0 ? 'bold' : 'normal',
                color: selectedTab === 0 ? '#1B5E20' : '#555',  
                backgroundColor: selectedTab === 0 ? '#a5d6a7' : 'transparent',  
                borderRadius: '12px',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#81c784',  
                }
              }}
            />
            <Tab 
              label="Explore what's inside" 
              icon={<ParkIcon sx={{ fontSize: '1.5rem', color: selectedTab === 1 ? '#1B5E20' : '#555' }} />}
              sx={{
                fontWeight: selectedTab === 1 ? 'bold' : 'normal',
                color: selectedTab === 1 ? '#1B5E20' : '#555',  
                backgroundColor: selectedTab === 1 ? '#a5d6a7' : 'transparent',  
                borderRadius: '12px',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#81c784',
                }
              }}
            />
          </Tabs>

          {/* Tab Content */}
          {selectedTab === 0 && (
            <Box p={3}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#1B5E20',textAlign: 'center' }}>
                About {park.name}
              </Typography>
              {/* Slider (Move it here) */}
              <Box sx={{ width: '100%', mt: 4 }}>
  <Slider
    value={selectedHour}
    min={0}
    max={23}
    step={1}
    marks={[
      { value: 0, label: '12 AM' },
      { value: 6, label: '6 AM' },
      { value: 12, label: '12 PM' },
      { value: 18, label: '6 PM' },
      { value: 23, label: '11 PM' }
    ]}
    valueLabelDisplay="on"
    onChange={handleSliderChange}
    sx={{
      '& .MuiSlider-thumb': {
        backgroundColor: '#ffffff',  // White thumb
        border: '2px solid #388E3C',  // Green border around thumb
        width: 20,  // Thumb size
        height: 20, // Thumb size
      },
      '& .MuiSlider-track': {
        backgroundColor: '#388E3C',  // Green track
        height: 8,  // Track height
      },
      '& .MuiSlider-rail': {
        backgroundColor: '#D32F2F',  // Red rail for unselected part
        height: 8,  // Rail height
      },
      '& .MuiSlider-mark': {
        backgroundColor: '#81c784',  // Mark color
        height: 10,  // Mark height
        width: 2,    // Mark width
      },
      '& .MuiSlider-markLabel': {
        color: '#388E3C',  // Label color
        fontSize: '0.875rem',  // Smaller font size
        fontWeight: 'bold',
      },
      '& .MuiSlider-valueLabel': {
        backgroundColor: '#388E3C',  // Background of value label
      },
    }}
  />
</Box>

              {/* Display the appropriate emoji */}
              <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                <Typography variant="h8" sx={{ mb: 2, fontWeight: 'bold', color: '#1B5E20' }}>
                  Crowding Level in selected Time(Hour): {selectedHour}:00<div alignItems="center">{renderEmoji(crowdingLevelForSelectedHour)}</div>
                </Typography>
                
              </Box>
              <TableContainer 
      component={Paper} 
      sx={{ 
        boxShadow: 3, 
        borderRadius: '15px', 
        overflow: 'hidden', 
        width: '100%', 
        margin: '0 auto',
        maxWidth: '100%',
      }}
    >
      <Table size="small" aria-label="park info table" sx={{ backgroundColor: '#f9f9f9', tableLayout: 'auto' }}>
        <TableBody>
          {/* Opening Hours */}
          <TableRow sx={{ height: 'auto' }}>
            <TableCell sx={{ fontWeight: 'bold', color: '#2E7D32', padding: '8px', fontSize: '1rem' }}>
              Opening Hours
            </TableCell>
            <TableCell align="center" sx={{ color: '#388E3C', wordBreak: 'break-word', padding: '8px' }}>
              {parkInfo.opening_hours}
            </TableCell>
          </TableRow>

          {/* User Rating */}
          <TableRow sx={{ height: 'auto' }}>
            <TableCell sx={{ fontWeight: 'bold', color: '#2E7D32', padding: '8px', fontSize: '1rem' }}>
              User Rating
            </TableCell>
            <TableCell align="center" sx={{ color: '#388E3C', wordBreak: 'break-word', padding: '8px' }}>
              {parkInfo.user_rating}/5
            </TableCell>
          </TableRow>

          {/* Pet Friendly */}
          <TableRow sx={{ height: 'auto' }}>
            <TableCell sx={{ fontWeight: 'bold', color: '#2E7D32', padding: '8px', fontSize: '1rem' }}>
              Pet Friendly
            </TableCell>
            <TableCell align="center" sx={{ color: parkInfo.pet_friendly === 'Yes' ? '#2E7D32' : '#D32F2F', wordBreak: 'break-word', padding: '8px' }}>
              {parkInfo.pet_friendly === 'Yes' ? 'Yes' : 'No'}
            </TableCell>
          </TableRow>

          {/* Wheelchair Accessibility */}
          <TableRow sx={{ height: 'auto' }}>
            <TableCell sx={{ fontWeight: 'bold', color: '#2E7D32', padding: '8px', fontSize: '1rem' }}>
              Wheelchair Accessibility
            </TableCell>
            <TableCell align="center" sx={{ color: '#388E3C', wordBreak: 'break-word', padding: '8px' }}>
              {parkInfo.wheelchair_accessibility}
            </TableCell>
          </TableRow>

          {/* Sustainability Score */}
          <TableRow sx={{ height: 'auto' }}>
            <TableCell sx={{ fontWeight: 'bold', color: '#2E7D32', padding: '8px', fontSize: '1rem' }}>
              Sustainability Score
            </TableCell>
            <TableCell align="center" sx={{ color: '#388E3C', wordBreak: 'break-word', padding: '8px' }}>
              {parkInfo.sustainability_score}/5
            </TableCell>
          </TableRow>

          {/* Amenities */}
          <TableRow sx={{ height: 'auto' }}>
            <TableCell sx={{ fontWeight: 'bold', color: '#2E7D32', padding: '8px', fontSize: '1rem' }}>
              Amenities
            </TableCell>
            <TableCell align="left" sx={{ wordBreak: 'break-word', padding: '8px' }}>
              <Box display="flex" flexDirection="column" gap={2}>
                {amenitiesLegend.map((amenity) => {
                  const hasAmenity = parkInfo.amenities.some(
                    (item) => normalizeString(item) === normalizeString(amenity.name)
                  );

                  return (
                    <Box key={amenity.name} display="flex" alignItems="center">
                      {hasAmenity ? (
                        <CheckCircleIcon sx={{ color: '#2E7D32', marginRight: '8px' }} />
                      ) : (
                        <CancelIcon sx={{ color: '#D32F2F', marginRight: '8px' }} />
                      )}

                      <img
                        src={amenity.icon}
                        alt={amenity.name}
                        style={{ width: '25px', height: '25px', marginRight: '8px' }}
                      />

                      <Typography sx={{ fontWeight: 'bold', color: '#388E3C',fontSize:'0.875rem' }}>{amenity.name}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </TableCell>
          </TableRow>

          {/* User Vibes */}
          <TableRow sx={{ height: 'auto' }}>
            <TableCell sx={{ fontWeight: 'bold', color: '#2E7D32', padding: '8px', fontSize: '1rem' }}>
              User Vibes
            </TableCell>
            <TableCell align="center" sx={{ color: '#388E3C', wordBreak: 'break-word', padding: '8px' }}>
              {parkInfo.associated_keywords.join(', ')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
              {/* "More Like This" Section */}
              {recommendations.length > 0 && (
                <Box mt={4}>
                  <Typography 
                    variant="h6" 
                    sx={{ mb: 2, fontWeight: 'bold', color: '#1B5E20', textAlign: 'center', fontSize: '20px' }}>
                    More Like This <span style={{ fontSize: '24px' }}>✨</span>
                  </Typography>

                  {recommendations.map((space) => (
                    <Box
                      key={space.id}
                      sx={{
                        mb: 2,  // Reduced margin bottom
                        padding: '12px',  // Reduced padding
                        background: '#8FBC8B',
                        borderRadius: '12px',  // Slightly smaller border radius
                        boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)',  // Adjusted shadow for a smaller card
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.15)',
                          transform: 'translateY(-3px)',
                        },
                        position: 'relative',
                        maxWidth: '100%',
                      }}
                    >
                      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                        {/* Park Name */}
                        <Typography
                          variant="h7"
                          sx={{
                            color: 'white',
                            fontWeight: 'bold',
                            mb: 0.5,  // Less margin at the bottom
                            textAlign: 'center',
                            fontSize: '16px',  // Reduced font size
                          }}
                        >
                          {space.name}
                        </Typography>

                        {/* Crowding Level */}
                        <Typography 
                          variant="body2" 
                          sx={{ color: 'white', textAlign: 'center', mb: 0.5, fontSize: '14px' }}>
                          Crowding Level: 
                          <span 
                            style={{
                              fontWeight: 'bold',
                              color: space.crowding_level === 'Low' 
                                ? '#388E3C' 
                                : space.crowding_level === 'Medium' 
                                ? '#F57C00' 
                                : '#D32F2F',
                            }}
                          >
                            {` ${space.crowding_level}`}
                          </span>
                        </Typography>

                        {/* Distance */}
                        <Typography 
                          variant="body2" 
                          sx={{ color: 'white', textAlign: 'center', fontSize: '14px' }}>
                          {space.distance?.toFixed(2)} km away
                        </Typography>

                        {/* Centered "Explore" Button */}
                        <Box display="flex" justifyContent="center" mt={1}>
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: '#006400',
                              color: 'white',
                              '&:hover': {
                                backgroundColor: '#388E3C',
                              },
                              fontWeight: 'bold',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '6px 24px',  // Reduced padding for smaller button
                              borderRadius: '15px',  // Reduced border-radius
                              textTransform: 'none',
                              fontSize: '14px',  // Smaller button text
                            }}
                            onClick={() => navigate('/explore-park', { state: { park: space, placeId: space.id, category: space.category } })}
                          >
                            <ParkIcon sx={{ mr: 0.5, fontSize: '18px' }} /> {/* Smaller icon */}
                            Explore
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}

            </Box>
          )}

{selectedTab === 1 && (
  <Box p={3}>
    {/* Instructional Guide Text */}
    {!selectedAmenity && (
      <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', fontStyle: 'italic', color: '#388E3C' }}>
        Click on an amenity on the map, then press the "Start Navigate" button here to get the navigation guide.
      </Typography>
    )}

    {/* Once an Amenity is Selected */}
    {selectedAmenity && (
      <>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#1B5E20' }}>
          Navigate to {selectedAmenity.type}  {/* Display the selected amenity type */}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Click the button below to start navigating to the {selectedAmenity.type}.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleStartNavigate}
          sx={{ mt: 2 }}
        >
          Start Navigate
        </Button>

        {/* Display turn-by-turn directions */}
        {directions && directions.steps && (
          <Box mt={3}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1B5E20' }}>Turn-by-Turn Directions:</Typography>
            {directions.steps.map((step, index) => (
              <Typography key={index} variant="body1" color="textSecondary" sx={{ mb: 1 }}>
                {index + 1}. {step.maneuver.instruction}
              </Typography>
            ))}
          </Box>
        )}
      </>
    )}
  </Box>
)}

        </Box>
      </Box>

      {/* Bottom Buttons */}
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          onClick={() => navigate(-1)}  // Go back to the previous page
          sx={{
            color: '#1B5E20',
            borderColor: '#1B5E20',
            '&:hover': {
              backgroundColor: '#e0f2f1',
              borderColor: '#1B5E20',
            },
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate('/')}  // Go to homepage
          sx={{
            backgroundColor: '#1B5E20',
            color: 'white',
            '&:hover': {
              backgroundColor: '#2E7D32',
            },
          }}
        >
          Go to Homepage
        </Button>
      </Box>
    </Box>
  );
};

export default ExploreParkPage;
