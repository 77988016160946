import React from 'react';
import { Box, Typography } from '@mui/material';
import './FlipCard.css';

const FlipCard = ({ frontText, backText, fontSize = '18px' }) => (
    <Box className="flip-card" style={{ width: '100%', marginBottom: '15px', height: '170px' }}> {/* Adjusted height */}
        <Box className="flip-card-inner">
            <Box className="flip-card-front" aria-label="Front of the card">
                <Typography variant="h6" style={{ color: '#FFFFFF', textAlign: 'center', padding: '15px', fontSize }}> {/* Consistent padding */}
                    {frontText}
                </Typography>
            </Box>
            <Box className="flip-card-back" aria-label="Back of the card">
                <Typography variant="h6" style={{ color: '#FFFFFF', textAlign: 'center', padding: '15px', fontSize }}> {/* Consistent padding */}
                    {backText}
                </Typography>
            </Box>
        </Box>
    </Box>
);

export default FlipCard;
