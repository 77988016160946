import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ForestIcon from '@mui/icons-material/Forest'; // MUI Icon for environmental
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // MUI Icon for economic benefits
import PeopleIcon from '@mui/icons-material/People'; // MUI Icon for social benefits
import gardenImage from '../assets/images/garden.jpeg'; // Image for the hero section

// Styled components for the page layout
const PageContainer = styled('div')({
  width: '100vw',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#f5f4e2',
});

const HeroSection = styled('section')({
  width: '100%',
  height: '70vh',
  background: `url(${gardenImage}) center/cover no-repeat`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  textAlign: 'center',
  padding: '0 20px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
});

const HeroTitle = styled(Typography)({
  fontSize: '48px',
  fontWeight: 'bold',
  textShadow: '2px 2px 8px rgba(0,0,0,0.5)',
});

const Section = styled('section')({
  maxWidth: '1000px',
  marginBottom: '50px',
  textAlign: 'center',
  padding: '20px',
});

const SectionTitle = styled(Typography)({
  fontSize: '36px',
  color: '#417446', // Fern Green
  marginBottom: '20px',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    width: '50px',
    height: '3px',
    backgroundColor: '#598f57', // Middle Green
    bottom: '-10px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
});

const SectionText = styled(Typography)({
  fontSize: '18px',
  color: '#333',
  lineHeight: '1.6',
  marginBottom: '30px',
});

const BenefitList = styled('ul')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
  gap: '20px',
  paddingLeft: '0',
  margin: '0 auto',
  maxWidth: '1000px',
});

const BenefitItem = styled('li')({
  backgroundColor: '#e6deb8',
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
  },
});

const BenefitIcon = styled('div')({
  fontSize: '50px',
  marginBottom: '10px',
  color: '#417446', // Fern Green for icons
});

const CallToActionButton = styled(Button)({
  backgroundColor: '#598f57',
  color: '#fff',
  padding: '15px 40px',
  fontSize: '18px',
  borderRadius: '25px',
  marginTop: '30px',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: '#417446',
  },
});

const CanvaEmbedWrapper = styled('div')({
  position: 'relative',
  width: '100%',
  height: '0',
  paddingTop: '75%', // Aspect ratio for the iframe
  boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
  marginTop: '1.6em',
  marginBottom: '0.9em',
  overflow: 'hidden',
  borderRadius: '8px',
});

const CanvaEmbedIframe = styled('iframe')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  border: 'none',
  padding: '0',
  margin: '0',
});

export default function WhyGreenSpaces() {
  return (
    <PageContainer>
      {/* Hero Section */}
      <HeroSection>
        <HeroTitle variant="h1">Why Green Spaces Matter</HeroTitle>
      </HeroSection>

      {/* Impact Section */}
      <Section>
        <SectionTitle variant="h3">The Importance of Green Spaces</SectionTitle>
        <SectionText>
          Green spaces contribute to our well-being, help combat climate change, and enhance the beauty of our cities. From parks to urban gardens, they offer us a refuge from the urban hustle and improve our mental and physical health.
        </SectionText>
      </Section>

      {/* Benefits Section with Custom Icons */}
      <Section>
        <SectionTitle variant="h3">How Green Spaces Benefit You and the City</SectionTitle>
        <BenefitList>
          <BenefitItem>
            <BenefitIcon>
              <ForestIcon fontSize="inherit" />
            </BenefitIcon>
            <Typography>Environmental Benefits</Typography>
            <Typography variant="body2">Reduce CO2, promote biodiversity, and cool urban areas.</Typography>
          </BenefitItem>
          <BenefitItem>
            <BenefitIcon>
              <AttachMoneyIcon fontSize="inherit" />
            </BenefitIcon>
            <Typography>Economic Benefits</Typography>
            <Typography variant="body2">Increase property values and reduce healthcare costs.</Typography>
          </BenefitItem>
          <BenefitItem>
            <BenefitIcon>
              <PeopleIcon fontSize="inherit" />
            </BenefitIcon>
            <Typography>Social Benefits</Typography>
            <Typography variant="body2">Foster community engagement and social interactions.</Typography>
          </BenefitItem>
        </BenefitList>
      </Section>

      {/* Canva Visualization Section */}
      <Section>
        <SectionTitle variant="h3">Green Spaces Visualization</SectionTitle>
        <SectionText>
          Explore how green spaces impact our city through the interactive visualization below:
        </SectionText>
        <CanvaEmbedWrapper>
          <CanvaEmbedIframe
            loading="lazy"
            src="https://www.canva.com/design/DAGRGoWCQnM/OGU5E_E1K4ypl5KsqzxS_A/watch?embed"
            allowFullScreen
            title="Green Spaces Visualization"
          />
        </CanvaEmbedWrapper>
      </Section>

      {/* Call-to-Action */}
      <Section>
        <SectionTitle variant="h3">Explore Green Spaces Near You</SectionTitle>
        <SectionText>
          Find nearby parks and gardens where you can unwind, play, and enjoy the beauty of nature.
        </SectionText>
        <CallToActionButton href="/nearby-green-spaces">Discover Green Spaces</CallToActionButton>
      </Section>
    </PageContainer>
  );
}
