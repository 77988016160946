import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import { Box, Grid, Typography, Button, Divider } from '@mui/material';
import sampleImage from '../../assets/UIdesign/UI img_1.jpeg';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import L from "leaflet";

// Function to calculate population density change
const calculateChange = (area, pop2016, pop2021) => {
  if (area > 0) {
    const density2016 = pop2016 / area;
    const density2021 = pop2021 / area;
    return density2021 - density2016;
  }
  return undefined;
};

// Function to get color based on change value
const getColor = (change) => {
  if (change === undefined) {
    return "#f0f0f0";
  }
  return change > 0 ? `rgba(0, ${Math.min(change * 20, 255)}, 0, 0.7)` : `rgba(0, 0, ${Math.min(-change * 20, 255)}, 0.7)`;
};

// Define the style for each area in the GeoJSON
const geojsonStyle = (feature) => {
  const { Area_km2, Population_2016, Population_2021 } = feature.properties;
  const change = calculateChange(Area_km2, Population_2016, Population_2021);
  return {
    fillColor: getColor(change),
    weight: 2,
    opacity: 1,
    color: "white",
    fillOpacity: 0.7,
  };
};

// Function to get arrow icon for population change
const getArrowIcon = (change, color) => {
  const arrowStyle = `style="color:${color};"`;
  if (change > 0) {
    return `<span ${arrowStyle}>&#9650;</span>`;
  } else if (change < 0) {
    return `<span ${arrowStyle}>&#9660;</span>`;
  } else {
    return '';
  }
};

// Legend component for the map
const Legend = () => {
  const map = useMap();
  useEffect(() => {
    const legend = L.control({ position: "bottomright" });
    legend.onAdd = function () {
      const div = L.DomUtil.create("div", "info legend");
      const grades = [-10, -5, 0, 5, 10];
      const labels = [];

      for (let i = 0; i < grades.length; i++) {
        div.innerHTML +=
          '<i style="background:' + getColor(grades[i]) + '"></i> ' +
          grades[i] + (grades[i + 1] ? '&ndash;' + grades[i + 1] + '<br>' : '+');
      }

      return div;
    };

    legend.addTo(map);
    return () => {
      map.removeControl(legend);
    };
  }, [map]);

  return null;
};

function HomePage2() {
  const theme = useTheme();
  const navigate = useNavigate();
  
  // Fetch the geoJSON data for the map
  const [geoData, setGeoData] = useState(null);
  useEffect(() => {
    fetch("/sa1_population_density_change.geojson")
      .then((response) => response.json())
      .then((data) => setGeoData(data))
      .catch((error) => console.error("Error loading GeoJSON data:", error));
  }, []);
  
  // Handle button click to navigate to nearby-green-spaces page
  const handleStartClick = () => {
    navigate("/nearby-green-spaces");
  };

  return (
    <Box 
      className="HomePageContent2" 
      sx={{ display: 'block', minHeight: '100vh', padding: { xs: '0px', sm: '10px', md: '12px' }, paddingBottom: { xs: '2vh', sm: '2vh' }, position: 'relative' }}
    >
      
      {/* Visualization Section */}
      <Box sx={{ marginTop: '50px', padding: { xs: '20px', sm: '40px', md: '60px' }, textAlign: 'center', borderRadius: '10px', backgroundColor: '#f9fafc' }}>
        <Typography variant="h4" sx={{ marginBottom: '20px', fontWeight: 'bold', color: '#2e7d32', fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}>
          Did You Know? Melbourne's Green Spaces Are Shrinking
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '30px', fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.6rem' }, lineHeight: 1.8, color: '#555' }}>
          Melbourne City is facing a significant reduction in per capita green space due to high-density development. With a forecasted 25% decrease in green space by 2040, this problem especially affects busy parents who rely on safe, well-maintained parks for their children.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: { xs: '1.4rem', sm: '1.6rem', md: '1.8rem' }, marginBottom: '30px', color: '#2e7d32' }}>
          How might we promote using existing green spaces to build a sustainable, livable city?
        </Typography>

        {/* Leaflet Map Section */}
        <Box sx={{ marginTop: '20px', height: '60vh', width: '100%', boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", borderRadius: '10px', overflow: 'hidden' }}>
          <MapContainer center={[-37.8136, 144.9631]} zoom={12} style={{ height: "100%", width: "100%" }}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            />
            {geoData && (
              <GeoJSON data={geoData} style={geojsonStyle} onEachFeature={(feature, layer) => {
                const { SA1, Population_2016, Population_2021, Area_km2 } = feature.properties;
                const change = calculateChange(Area_km2, Population_2016, Population_2021);
                const color = getColor(change);
                const arrow = getArrowIcon(change, color);
                layer.bindPopup(
                  `<b>SA1: </b>${SA1}<br>
                  <b>Change (people per km²): </b>${change !== undefined ? change.toFixed(2) : 'undefined'} ${arrow}<br>
                  <b>Population 2016: </b>${Population_2016}<br>
                  <b>Population 2021: </b>${Population_2021}<br>
                  <b>Square km: </b>${Area_km2}`
                );
              }} />
            )}
            <Legend />
          </MapContainer>
        </Box>
      </Box>

      <Grid container spacing={4} alignItems="center" sx={{ marginTop: '40px' }}>
        {/* Text Section */}
        <Grid item xs={12} md={6} sx={{ paddingRight: { md: '30px' }, paddingLeft: { md: '30px' }, textAlign: { xs: 'center', md: 'left' } }}>
          <motion.div initial={{ x: 500, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 2 }}>
            <Typography variant="h6" sx={{ color: '#2e7d32', fontWeight: 'bold', marginBottom: '20px', fontSize: { xs: '2.2rem', sm: '2.4rem', md: '2.8rem', lg: '3rem' }, lineHeight: '1.3' }}>
              Discover Safe, Accessible Green Spaces in Melbourne CBD
            </Typography>
          </motion.div>

          <Divider sx={{ marginBottom: '20px', backgroundColor: '#2e7d32' }} />

          <motion.div initial={{ x: 500, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 2 }}>
            <Typography variant="body2" sx={{ marginBottom: '20px', lineHeight: 1.7, fontWeight: '400', fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.5rem' }, color: '#555' }}>
              Explore Melbourne's lush urban oases, and discover safe, accessible parks, gardens, and green spaces designed for relaxation and sustainable living.
            </Typography>
          </motion.div>

          <Box textAlign={{ xs: 'center', md: 'left' }} sx={{ marginTop: '20px' }}>
            <Button variant="contained" sx={{ backgroundColor: '#2e7d32', color: '#fff', padding: '12px 30px', fontSize: '1.2rem', fontWeight: 'bold', borderRadius: '30px', '&:hover': { backgroundColor: '#1b5e20' } }} onClick={handleStartClick}>
              Find Nearby Green Spaces
            </Button>
          </Box>
        </Grid>

        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <motion.div initial={{ x: -500, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 2 }}>
            <Box component="img" src={sampleImage} alt="Park Image" sx={{ width: '100%', height: 'auto', borderRadius: '10px', boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }} />
          </motion.div>
        </Grid>
      </Grid>

      {/* Additional Information Section */}
      <Box sx={{ marginTop: '50px', padding: { xs: '20px', sm: '40px', md: '60px' }, backgroundColor: '#f0f4f3', textAlign: 'center', borderRadius: '10px' }}>
        <Typography variant="h4" sx={{ marginBottom: '20px', fontWeight: 'bold', color: '#2e7d32' }}>
          Why Green Spaces Matter
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '20px', fontSize: '1.2rem', lineHeight: '1.7', color: '#555' }}>
          Green spaces in urban areas play a crucial role in promoting mental and physical well-being, improving air quality, and fostering social connections. Our goal is to help you easily access and enjoy these vital natural spaces.
        </Typography>
        <Button 
          variant="outlined" 
          sx={{ padding: '10px 20px', borderColor: '#2e7d32', color: '#2e7d32', '&:hover': { backgroundColor: '#2e7d32', color: '#fff' } }}
          onClick={() => navigate("/HomePageVis")}
        >
          Learn More
        </Button>
      </Box>
    </Box>
  );
}

export default HomePage2;
