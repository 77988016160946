import React from 'react';
import { Box, Typography, Divider, Grid } from '@mui/material';

const InfoCardsHome = () => {
  return (
    <>
      <Box
        sx={{
          padding: '40px 20px',
          backgroundColor: '#f5f5f5', // light grey background
          textAlign: 'center',
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={10} md={8}>
            <Typography
              variant="h4"
              sx={{
                marginBottom: '20px',
                fontWeight: 'bold',
                color: '#2e7d32', // dark green color for the title
                fontSize: {
                  xs: '1.5rem', // small screens
                  sm: '1.75rem', // medium screens
                  md: '2.25rem', // large screens
                  lg: '2.5rem', // extra large screens
                },
              }}
            >
              Discover Safe, Accessible Green Spaces in Melbourne CBD
            </Typography>

            <Divider
              sx={{
                width: '80%',
                margin: '20px auto',
                borderColor: '#2e7d32',
              }}
            />

            <Typography
              variant="h6"
              sx={{
                marginBottom: '15px',
                color: '#555',
                fontSize: {
                  xs: '0.875rem',
                  sm: '1rem',
                  md: '1.125rem',
                  lg: '1.25rem',
                },
                lineHeight: 1.6,
                maxWidth: '800px',
                margin: '0 auto',
              }}
            >
              Find the perfect park for picnics, playtime, and relaxation with our easy-to-use app.
            </Typography>

            <Typography
              variant="h6"
              sx={{
                color: '#555',
                fontSize: {
                  xs: '0.875rem', // small screens
                  sm: '1rem', // medium screens
                  md: '1.125rem', // large screens
                  lg: '1.25rem', // extra large screens
                },
                lineHeight: 1.6,
                maxWidth: '800px',
                margin: '0 auto 20px',
              }}
            >
              Explore Melbourne's lush urban oases and create lasting memories with your loved ones.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InfoCardsHome;
