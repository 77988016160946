import React, { useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Box, Typography } from '@mui/material';
import download12 from '../../assets/UIdesign/download-12.png'; 
import greencity from '../../assets/UIdesign/green-city.png'; 
import family_pic from '../../assets/UIdesign/download-9.webp'; 
import scrolldownImage from '../../assets/UIdesign/drag-down.png'; 
import { useTheme, useMediaQuery } from '@mui/material';

function HomePage1() {
  const controls = useAnimation();

  // 强制在组件加载时触发动画
  useEffect(() => {
    controls.start("animate");
  }, [controls]);

  const textVariants_top = {
    initial: {
      x: 500,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.1,
      },
    },
  };

  const textVariants = {
    initial: {
      x: -500,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.1,
      },
    },
  };

  const backgroundVariants = {
    initial: {
      y: -500,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 2,
      },
    },
    scrollDown: {
      opacity: 0,
      y: 10,
      transition: {
        repeat: Infinity,
        duration: 2,
      },
    },
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  return (
    <>
      <Box
        className="HomePage1"
        sx={{
          display: "block",
          minHeight: '90vh',
          padding: { xs: '5px', sm: '10px', md: '15px' },
          paddingTop: { xs: '0' },
          paddingBottom: { xs: '20vh', sm: '15vh' },
        }}
      >
        {/* Top text and icons */}
        <motion.div
          variants={textVariants_top}
          initial="initial"
          animate={controls}
          style={{ overflowX: 'hidden' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%',
              margin: '2px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: '#2f4f4f',
                fontWeight: 'bold',
                whiteSpace: 'normal',
                fontSize: {
                  xs: '1.00rem',
                  sm: '1.2rem',
                  md: '1.4rem',
                  lg: '1.6rem',
                  xl: '1.8rem',
                },
                fontFamily: 'Cursive',
                fontStyle: 'italic',
              }}
            >
              Building a Sustainable Melbourne City <br /> for Future Generations!
            </Typography>

            <Box>
              <img
                src={greencity}
                alt="iconGreenCity"
                style={{
                  height: '10vh',
                  maxWidth: '90px',
                }}
              />
            </Box>
          </Box>
        </motion.div>

        {/* Urban backgrounds and family pic */}
        <Box
          className="HomePageContent1"
          sx={{
            display: "block",
            padding: { xs: '0px', sm: '10px', md: '12px' },
          }}
        >
          {/* Text: put in front of the picture */}
          <motion.div
            className="TitleName"
            variants={textVariants}
            initial="initial"
            animate={controls}
          >
            <Typography
              variant="h4"
              sx={{
                position: 'relative',
                color: '#333',
                fontWeight: 'bold',
                fontFamily: 'Sans-serif',
                textShadow: '1px 1px 2px black',
                zIndex: 10, 
                fontSize: {
                  xs: '1.6rem',
                  sm: '1.8rem',
                  md: '2rem',
                  lg: '2.2rem',
                  xl: '3.5rem',
                },
                marginTop: { xs: '3vh', sm: '10px', md: '5px', lg: '10px', xl: '10px' },
              }}
            >
              Green Finder: Where Families Discover a Sustainable Melbourne City
            </Typography>
          </motion.div>

          {/* Urban context */}
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              marginTop: { xs: '5vh', sm: '10px', md: '5px' },
            }}
          >
            {/* Background Pic */}
            <motion.div
              variants={backgroundVariants}
              initial="initial"
              animate={controls}
              style={{
                overflowX: 'hidden',
                maxWidth: '100%',
              }}
            >
              <Box
                component="img"
                src={download12}
                alt="Green City Illustration"
                sx={{
                  width: { xs: '120%', sm: '95%', md: '90%', lg: '65%' },
                  height: 'auto',
                  objectFit: 'cover',
                  marginLeft: { xs: '-8vw', sm: '0', md: '0' },
                }}
              />
            </motion.div>

            {/* Family Pic */}
            <motion.img
              src={family_pic}
              alt="People Illustration"
              variants={textVariants}
              initial="initial"
              animate={controls}
              transition={{ duration: 2, ease: 'easeInOut' }}
              style={{
                position: 'absolute',
                bottom: '-5vh',
                left: { xs: '30%', sm: '60%' },
                transform: 'translateX(-60%)',
                width: '20vh',
                height: 'auto',
                maxWidth: '600px',
                minWidth: '120px',
                overflowX: 'visible',
                maxWidth: '100%',
              }}
            />
          </Box>
        </Box>

        {/* Scroll Down */}
        <motion.img
          variants={backgroundVariants}
          animate="scrollDown"
          src={scrolldownImage}
          style={{
            position: 'absolute',
            bottom: isXs || isSm ? '4vh' : isMd ? '5vh' : '1vh',
            left: isXs ? '46%' : '47%',
            transform: 'translateX(-70%)',
            width: isXs ? '40px' : isSm ? '50px' : isMd ? '65px' : isLg ? '75px' : '85px',
            maxWidth: '100px',
            minWidth: '30px',
          }}
        />
      </Box>
    </>
  );
}

export default HomePage1;
