import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography, Button, Dialog, DialogContent, Tabs, Tab, List, ListItem, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import NavigationMap from './NavigationMap';
import mapboxgl from 'mapbox-gl';
import FlipCard from './FlipCard';
import earthGif from '../assets/images/earth.gif';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import ParkIcon from '@mui/icons-material/EmojiNature';
import EcoIcon from '@mui/icons-material/EnergySavingsLeaf';
import PropTypes from 'prop-types'; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import StraightIcon from '@mui/icons-material/ArrowForward';

import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const MemoizedMap = React.memo(({ userCoordinates, parkCoordinates, transportMode, routeOption, setIsScenicAvailable, setTurnByTurnDirections }) => {
  return (
    <Box
      width={{ xs: '100%', md: '70%' }}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      sx={{ height: '65vh', padding: 0, margin: 0 }}
    >
      <NavigationMap
        userCoordinates={userCoordinates}
        parkCoordinates={parkCoordinates}
        transportMode={transportMode}
        routeOption={routeOption} 
        setIsScenicAvailable={setIsScenicAvailable}
        setTurnByTurnDirections={setTurnByTurnDirections} 
        style={{ flex: 1, height: '100%', marginBottom: 0 }}
      />
    </Box>
  );
});

const NavigationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { park, userCoordinates, placeId, category } = location.state || {};

  const [selectedMode, setSelectedMode] = useState(null);
  const [selectedRouteOption, setSelectedRouteOption] = useState(null); 
  const [isScenicAvailable, setIsScenicAvailable] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [tabValue, setTabValue] = useState(0); 
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);
  const [turnByTurnDirections, setTurnByTurnDirectionsState] = useState([]);
  const [routeDistance, setRouteDistance] = useState(0); 
  const [routeDuration, setRouteDuration] = useState(0); 

  const parkCoordinates = useMemo(() => {
    return park?.coords
      ? {
          latitude: parseFloat(park.coords.split(',')[0].replace(/[()]/g, '')),
          longitude: parseFloat(park.coords.split(',')[1].replace(/[()]/g, '')),
        }
      : null;
  }, [park]);

  const setTurnByTurnDirections = useCallback((directions) => {
    setTurnByTurnDirectionsState(directions);

    const totalDistance = directions.reduce((acc, step) => acc + step.distance, 0) / 1000; 
    const totalDuration = directions.reduce((acc, step) => acc + step.duration, 0) / 60;

    setRouteDistance(totalDistance);
    setRouteDuration(totalDuration);
  }, []);

  useEffect(() => {
    if (placeId && category) {
      console.log('Place ID:', placeId);
      console.log('Category:', category);
      // You can use placeId and category in any fetch or API logic here
    }
  }, [placeId, category]);


  // Automatically set default route to eco-friendly when mode is selected
  useEffect(() => {
    if (selectedMode) {
      setSelectedRouteOption('eco-friendly');
    }
  }, [selectedMode]);

  useEffect(() => {
    let intervalId;
    if (isCountingDown && countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(intervalId);
      navigate('/explore-park', { state: { park, userCoordinates, placeId, category } });
    }
    return () => clearInterval(intervalId);
  }, [countdown, isCountingDown, navigate, park, userCoordinates, placeId, category]);

  const handleCompleteTrip = () => {
    setOpenDialog(true);
    setIsCountingDown(true);
    setCountdown(5);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderInstructionIcon = (instruction) => {
    if (instruction.toLowerCase().includes('left')) {
      return <TurnLeftIcon sx={{ color: '#4CAF50', marginRight: '10px' }} />;
    } else if (instruction.toLowerCase().includes('right')) {
      return <TurnRightIcon sx={{ color: '#FF5722', marginRight: '10px' }} />;
    } else {
      return <StraightIcon sx={{ color: '#007FFF', marginRight: '10px' }} />;
    }
  };

  const getBackgroundColor = (index) => {
    return index % 2 === 0 ? '#ACE1AF' : '#E8F5E9';
  };

  useEffect(() => {
    const scrollableElement = document.getElementById('scrollable-right-column');

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollableElement;
      if (scrollTop + clientHeight >= scrollHeight) {
        setShowScrollIndicator(false);
      } else {
        setShowScrollIndicator(true);
      }
    };

    scrollableElement.addEventListener('scroll', handleScroll);

    return () => {
      scrollableElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" sx={{ minHeight: '80vh', backgroundColor: '#F1F8E9', pt: 4 }}>
  
      {/* Move the buttons to the top of the page */}
      <Box mb={2} display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          onClick={() => navigate(-1)}  // Go back to the previous page
          sx={{
            color: '#1B5E20',
            borderColor: '#1B5E20',
            '&:hover': {
              backgroundColor: '#e0f2f1',
              borderColor: '#1B5E20',
            },
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleCompleteTrip}  // Trigger Complete Trip action
          sx={{
            backgroundColor: '#1B5E20',
            color: 'white',
            '&:hover': {
              backgroundColor: '#2E7D32',
            },
          }}
        >
          Complete Trip
        </Button>
      </Box>
  
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          color: '#2E7D32',
          textAlign: 'center',
          mb: 2,
          letterSpacing: '1px',
          paddingX: 2,
        }}
      >
        Navigation to {park?.name}
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        p={2}
        gap={2}
        sx={{ flex: 1, backgroundColor: '#F1F8E9' }}
      >
        <MemoizedMap 
          userCoordinates={userCoordinates} 
          parkCoordinates={parkCoordinates} 
          transportMode={selectedMode} 
          routeOption={selectedRouteOption} 
          setIsScenicAvailable={setIsScenicAvailable} 
          setTurnByTurnDirections={setTurnByTurnDirections} 
        />

        <Box
          id="scrollable-right-column"  
          width={{ xs: '100%', md: '30%' }}
          display="flex"
          flexDirection="column"
          sx={{
            backgroundColor: '#E8F5E9',
            borderRadius: '12px',
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)',
            height: '65vh',
            position: 'relative',
            overflowY: 'auto',  
          }}
        >
          <Box sx={{ backgroundColor: '#C8E6C9', boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)', position: 'sticky', top: 0, zIndex: 100 }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="navigation tabs" centered>
              <Tab label="Green Contribution" sx={{ fontWeight: 'bold' }}/>
              <Tab label="GO Time" sx={{ fontWeight: 'bold' }} />
            </Tabs>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <TabPanel value={tabValue} index={0}>
              <Box sx={{ width: '100%', textAlign: 'center', mb: 1, mt: 2 }}>
                <Typography variant="body1" sx={{ color: '#2E7D32', mb: 1, fontWeight: 'bold' }}>
                  Select your mode of transport:
                </Typography>
                <Box display="flex" justifyContent="center" gap={2} mb={1}>
                  <Button
                    variant={selectedMode === 'walking' ? 'contained' : 'outlined'}
                    color="primary"
                    onClick={() => setSelectedMode('walking')}
                    sx={{
                      backgroundColor: selectedMode === 'walking' ? '#388E3C' : 'transparent',
                      color: selectedMode === 'walking' ? 'white' : '#388E3C',
                      fontWeight: 'bold',
                      padding: '5px 10px',
                    }}
                    startIcon={<DirectionsWalkIcon />}
                  >
                    Walking
                  </Button>
                  <Button
                    variant={selectedMode === 'cycling' ? 'contained' : 'outlined'}
                    color="primary"
                    onClick={() => setSelectedMode('cycling')}
                    sx={{
                      backgroundColor: selectedMode === 'cycling' ? '#388E3C' : 'transparent',
                      color: selectedMode === 'cycling' ? 'white' : '#388E3C',
                      fontWeight: 'bold',
                      padding: '5px 10px',
                    }}
                    startIcon={<DirectionsBikeIcon />}
                  >
                    Cycling
                  </Button>
                </Box>
              </Box>

              {selectedMode && (
                <Box sx={{ width: '80%', textAlign: 'center', mt: 1, margin: '0 auto' }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: '#2E7D32',
                      mb: 1,
                      fontWeight: 'bold',
                    }}
                  >
                    Select Route Option:
                  </Typography>
                
                  <Box display="flex" justifyContent="center" gap={2} mb={2}>
                    <Button
                      variant={selectedRouteOption === 'scenic' ? 'contained' : 'outlined'}
                      color="primary"
                      onClick={() => setSelectedRouteOption('scenic')}
                      sx={{
                        backgroundColor: selectedRouteOption === 'scenic' ? '#388E3C' : 'transparent',
                        color: selectedRouteOption === 'scenic' ? 'white' : '#388E3C',
                        fontWeight: 'bold',
                        padding: '5px 15px',
                        minWidth: '150px',
                      }}
                      startIcon={<ParkIcon />} 
                      disabled={!isScenicAvailable}
                    >
                      Scenic Route
                    </Button>
                    <Button
                      variant={selectedRouteOption === 'eco-friendly' ? 'contained' : 'outlined'}
                      color="primary"
                      onClick={() => setSelectedRouteOption('eco-friendly')}
                      sx={{
                        backgroundColor: selectedRouteOption === 'eco-friendly' ? '#388E3C' : 'transparent',
                        color: selectedRouteOption === 'eco-friendly' ? 'white' : '#388E3C',
                        fontWeight: 'bold',
                        padding: '5px 15px',
                        minWidth: '150px',
                      }}
                      startIcon={<EcoIcon />} 
                    >
                      Eco-friendly Route
                    </Button>
                  </Box>
                </Box>                    
              )}

              {routeDistance > 0 && routeDuration > 0 && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <Typography variant="body2" sx={{ color: '#2E7D32', fontWeight: 'bold', fontSize: '18px' }}>
                    Total Distance: {routeDistance.toFixed(2)} km
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#2E7D32', fontWeight: 'bold', fontSize: '18px' }}>
                    Estimated Time: {routeDuration.toFixed(0)} mins
                  </Typography>
                </Box>
              )}

              {selectedMode && selectedRouteOption && (
                <Box sx={{ width: '80%', textAlign: 'center', margin: '0 auto', mt: 2 }}>
                    <Typography
                    variant="body1"
                    sx={{
                        color: '#2E7D32',
                        mb: 1,
                        fontSize: '14px'
                    }}
                    >
                    Hover to see the environmental impact of your trip.
                    </Typography>

                    <FlipCard
                        frontText={
                            <span>
                            <strong style={{ fontSize: '22px', color: '#ACE1AF' }}> Did you know ?</strong><br />
                            By {selectedMode === 'walking' ? <strong style={{color: '#006400'}}>walking</strong> : <strong style={{color: '#006400'}}>cycling</strong>} just 
                            <strong style={{ fontSize: '16px', color: '#006400' }}> {routeDistance.toFixed(2)} km</strong> a day for a year, you can make a significant impact
                            </span>
                        }

                        backText={
                            <span>
                                You could save approx. <strong style={{ color: '#8FBC8B', fontSize: '18px' }}>{selectedMode === 'walking' ? (routeDistance * 0.21 * 365).toFixed(2) : (routeDistance * 0.16 * 365).toFixed(2)} kg</strong> of CO2!<br />
                                which equivalents to planting <strong style={{ color: '#8FBC8B', fontSize: '20px' }}>{Math.round(selectedMode === 'walking' ? (routeDistance * 0.21 * 365 / 21) : (routeDistance * 0.16 * 365 / 21))}</strong> trees. <span style={{ fontSize: '30px' }}>🌳</span>
                            </span>
                        }
                        fontSize="16px"
                    />
                    <Typography
                        variant="body1"
                        sx={{
                            color: '#2E7D32',
                            mb: 1,
                            fontSize: '14px'
                        }}
                    >
                        Click on the "GO TIME" tab to see the detailed navigation
                    </Typography>
                </Box>
              )}
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Typography variant="body1" sx={{ color: '#2E7D32', mb: 1, fontWeight: 'bold' }}>
                Turn-by-turn Navigation:
              </Typography>
              <List>
                {turnByTurnDirections.length > 0 ? (
                  turnByTurnDirections.map((step, index) => (
                    <ListItem key={index} sx={{ backgroundColor: getBackgroundColor(index), padding: '10px 0' }}>
                      {renderInstructionIcon(step.instruction)}
                      <ListItemText
                        primary={step.instruction}
                        secondary={`${Math.round(step.distance)} meters (${Math.round(step.duration)} seconds)`}
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography>No turn-by-turn directions available.</Typography>
                )}
              </List>
            </TabPanel>

            {showScrollIndicator && (
            <Box
                sx={{
                position: 'sticky', 
                bottom: '20px', 
                display: 'flex', 
                justifyContent: 'center',
                zIndex: 10,
                padding: '5px',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: '50%',
                animation: 'pulse 1.5s infinite',
                }}
            >
                <ExpandMoreIcon
                sx={{
                    fontSize: '2.5rem',
                    color: '#1B5E20',
                    filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2))',
                }}
                />
            </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent
          sx={{
            textAlign: 'center',
            p: 3,
            backgroundColor: '#E8F5E9',
            borderRadius: '12px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box display="flex" justifyContent="center" mb={2}>
            <img
              src={earthGif}
              alt="Earth animation"
              style={{ width: '80px', height: '80px' }}
            />
          </Box>

          <Typography
            variant="h6"
            color="#2E7D32"
            gutterBottom
            sx={{ fontWeight: 'bold', mb: 1 }}
          >
            Enjoy your time at {park?.name}!
          </Typography>

          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ fontStyle: 'italic', mb: 2 }}
          >
            Thank you for choosing a sustainable way to reach your destination.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: '#4CAF50',
              fontWeight: 'bold',
              mt: 2,
            }}
          >
            Redirecting you to explore "{park?.name}" in {countdown} seconds...
          </Typography>

          <Box
            mt={3}
            sx={{
              backgroundColor: '#C8E6C9',
              borderRadius: '5px',
              position: 'relative',
              height: '8px',
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#388E3C',
                height: '100%',
                width: `${(5 - countdown) * 20}%`,
                transition: 'width 1s ease-out',
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <style>
        {`
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.2); }
            100% { transform: scale(1); }
          }
        `}
      </style>

    </Box>
  );
};

export default NavigationPage;
