import React from 'react';
import { Box, Typography } from '@mui/material';
import bannerImage_1 from '../assets/images/Hawthorn.jpg';

const Banner = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: { xs: '400px', md: '400px' }, 
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
        flexDirection: 'column',
        overflow: 'hidden',
        width: '100%', 
        padding: '0 20px',
      }}
    >

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${bannerImage_1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(0.9) contrast(0.8) blur(1px)',
          zIndex: 0,
        }}
      />

      {/* 文本内容 */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          padding: '20px',
          borderRadius: '12px',
          width: '100%', 
          maxWidth: '100%', 
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 'bold',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)',
            fontFamily: 'Times, serif',
            fontSize: {
              xs: '1.75rem',
              sm: '2rem',
              md: '2.5rem',
              lg: '3rem',
            },
            lineHeight: 1.2,
            whiteSpace: 'normal',
            paddingTop: '80px',
          }}
        >
          Melbourne GreenScape:<br />Family-Friendly Park Finder
        </Typography>

        {children}
      </Box>
    </Box>
  );
};

export default Banner;
