import React from 'react';
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import NearbyGreenSpaces from './components/NearbyGreenSpaces';
import NavigationPage from './components/NavigationPage';
import PersistentDrawer from './components/PersistentDrawer';
import LoginPage from './components/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import ParkInfoDetailPage from './components/Epic3/ParkInfoDetailPage';
import ExploreGreenSpacePage from './components/Epic4/ExploreGreenSpacePage';
import HomePageVis from './components/HomePageVis';
import TestHome from './components/TestHome';
import LandingPage from './components/LandingPage';
import WhyGreenSpaces from './components/WhyGreenSpaces';
import AssessmentPage from './components/AssessmentPage';

function App() {
  return (
    <div className="App">
      <Routes>
        {/* Public Route for Login */}
        <Route path="/login" element={<LoginPage />} />

        {/* Protected Routes */}
        <Route
          path="*"
          element={
            <PrivateRoute
              element={
                <PersistentDrawer
                  content={
                    <Routes>
                      <Route path="/home" element={<Home />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/nearby-green-spaces" element={<NearbyGreenSpaces />} />
                      <Route path="/navigate" element={<NavigationPage />} />
                      <Route path="/ParkInfoDetailPage" element={<ParkInfoDetailPage />} />
                      <Route path="/explore-park" element={<ExploreGreenSpacePage />} /> 
                      <Route path="*" element={<Navigate to="/LandingPage" />} />
                      <Route path="/HomePageVis" element={<HomePageVis /> } />
                      <Route path="/TestHome" element={<TestHome /> } />
                      <Route path="/LandingPage" element={<LandingPage /> } />
                      <Route path="/why-green-spaces" element={<WhyGreenSpaces />} />
                      <Route path="/assessment" element={<AssessmentPage />} />
                    </Routes>
                  }
                />
              }
            />
          }
        />

        {/* Redirect any unmatched routes to login */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}

export default App;
