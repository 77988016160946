import React from 'react';
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import { Button, Box, Typography,Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import parkImage from '../assets/images/forest.png';
import Banner from '../components/Banner';
import InfoCardsHome from '../components/InfoCardsHome';

import royalbotanicgardens from '../assets/images/Royal_botanic.jpg';

const Home = () => {
  const navigate = useNavigate();

  const handleFindNearbyClick = () => {
    setTimeout(() => {
      navigate('/nearby-green-spaces');
    }, 500);
  };

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  // Dynamically set translateX and transparency effects
  const translateX = useTransform(scrollYProgress, [0, 0.5], [-window.innerWidth, 0]);
  const slide1Opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]); // Slide 1 disappear
  const slide2Opacity = useTransform(scrollYProgress, [0.5, 1], [0, 1]); // Slide 2 appear

  const styles = {
    slideContainer: {
      height: '100vh',  
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    }
  };

  // Define your styles directly in the React component
  const progressBarStyle = {
    position: 'fixed',
    top: 50,
    left: 0,
    right: 0,
    height: '30px',
    backgroundColor: '#8fb943', // Green color directly defined here
    transformOrigin: '0%',
    zIndex: 1001,
  };

  return (
    <>
    <motion.div
        className="progress-bar"
        style={{ ...progressBarStyle, scaleX: scrollYProgress }} // Merge dynamic scaling with predefined styles
      />

    <Grid
      container
      sx={{
        backgroundColor: 'rgba(150, 189, 149 , 0.5)',
        minHeight: '200vh', 
        padding: {
          xs: '5px',  
          sm: '10px',  
          md: '10px',  
        },
      }}
    >
      {/* Slide 1 */}
      <Grid item xs={12}>
        <motion.div style={{ ...styles.slideContainer, opacity: slide1Opacity }}>
          <Box
            maxWidth="100%"
            sx={{
              marginTop: '20px',
              marginBottom: '20px',
              textAlign: 'center',
              flex: 1,
            }}
          >
            <Banner>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 1.1 }}
                  transition={{
                    duration: 0.3,
                    ease: [0, 0.71, 0.2, 1.01],
                    scale: {
                      type: "spring",
                      damping: 5,
                      stiffness: 100,
                      restDelta: 0.001
                    }
                  }}
                >
                  <Button
                    onClick={handleFindNearbyClick}
                    sx={{
                      marginTop: '20px',
                      backgroundColor: '#0C7621',
                      color: 'white',
                      fontWeight: 'bold',
                      padding: '10px 20px',
                      '&:hover': { backgroundColor: '#2ABB48' },
                      fontSize: {
                        xs: '0.75rem !important', 
                        sm: '0.8rem !important',  
                        md: '1rem !important',    
                        lg: '1.25rem !important', 
                      },
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    LET'S EXPLORE
                    <img
                      src={parkImage}
                      alt="Forest Icon"
                      style={{
                        width: '30px',
                        height: '30px',
                        marginLeft: '10px', 
                      }}
                    />
                  </Button>
                </motion.div>
              </Box>
            </Banner>
            <InfoCardsHome />
          </Box>
        </motion.div>
      </Grid>

      {/* Slide 2 */}
      <Grid item xs={12}>
        <motion.div style={{ ...styles.slideContainer, opacity: slide2Opacity }}>
        <Box
      sx={{
        position: 'relative',
        height: { xs: '400px', md: '400px' }, 
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
        flexDirection: 'column',
        overflow: 'hidden',
        width: '100%', 
        padding: '0 20px',
      }}
    >

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${royalbotanicgardens})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(0.9) contrast(0.8) blur(1px)',
          zIndex: 0,
        }}
      />

      {/* 文本内容 */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          padding: '20px',
          borderRadius: '12px',
          width: '100%', 
          maxWidth: '100%', 
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 'bold',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)',
            fontFamily: 'Times, serif',
            fontSize: {
              xs: '1.75rem',
              sm: '2rem',
              md: '2.5rem',
              lg: '3rem',
            },
            lineHeight: 1.2,
            whiteSpace: 'normal',
            paddingTop: '80px',
          }}
        >
          How environment consicious on you?
        </Typography>


      </Box>
    </Box>
        </motion.div>
      </Grid>
    </Grid>

    </>
  );
};

export default Home;

