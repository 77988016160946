import {React,useRef} from 'react';
import { motion, useScroll, useTransform } from "framer-motion";
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import HomePage1 from './NewHomePage/HomePage1';
import HomePage2 from './NewHomePage/HomePage2';
import { width } from 'dom-helpers';

const TestHome = () => {
  const navigate = useNavigate();

  const handleFindNearbyClick = () => {
    setTimeout(() => {
      navigate('/nearby-green-spaces');
    }, 500);
  };

  const ref = useRef();

  const { scrollYProgress } = useScroll(
    {
      target:ref
    }
  );
  const slide1Opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]); // Slide 1 disappear
  const slide2Opacity = useTransform(scrollYProgress, [0.5, 1], [0, 1]); // Slide 2 appear

  const styles = {
    slideContainer: {
      // display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      margin: 0,
      padding: '2px',
      minHeight: '100vh',
      paddingBottom: '10vh'
    },
    slide2Container: {
      // display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      margin: 0,
      padding: 0,
      minHeight: '50vh'
    }
  };

  // Define your styles directly in the React component
  const progressBarStyle = {
    position: 'fixed',
    top: 50,
    left: 0,
    right: 0,
    height: '30px',
    width: '100%',
    backgroundColor: '#8fb943', // Green color directly defined here
    transformOrigin: '0%',
    zIndex: 1001,
    boxSizing: 'border-box',
  };

  return (
    <>
    <motion.div
        className="progress-bar"
        style={{ ...progressBarStyle, scaleX: scrollYProgress }} // Merge dynamic scaling with predefined styles
    />

<Box
  sx={{
    backgroundColor: 'rgba(150, 189, 149 , 0.5)',
    padding: '5px',
    overflowX: 'hidden', 
  }}
>
  {/* Slide 1 */}
  <motion.div style={{ ...styles.slideContainer, slide1Opacity }}>
    <HomePage1 />
  </motion.div>

  {/* Slide 2 */}
  <motion.div style={{ ...styles.slide2Container, slide2Opacity }}>
    <HomePage2 />
  </motion.div>
</Box>


    </>
  );
};

export default TestHome;
