import React, { useEffect, useRef, useState } from 'react';
import { Map, Marker, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import currentLocationIcon from '../../assets/exploreIcon/current_location.png';
import toiletIcon from '../../assets/exploreIcon/toilet.png';
import barbecueIcon from '../../assets/exploreIcon/barbecue.png';
import bicycleRailsIcon from '../../assets/exploreIcon/bicycle_rails.png';
import binIcon from '../../assets/exploreIcon/bin.png';
import drinkingFountainIcon from '../../assets/exploreIcon/drinking_fountain.png';
import picnicSettingIcon from '../../assets/exploreIcon/picnic_setting.png';
import playgroundIcon from '../../assets/exploreIcon/playground.png';
import seatIcon from '../../assets/exploreIcon/seat.png';

const ExploreMap = ({ parkCoordinates, amenities, onAmenityClick, directions }) => {
  const mapRef = useRef(null);

  // Track whether the route has been fitted, reset when new directions are provided
  const [routeFitted, setRouteFitted] = useState(false);

  // Mapping of amenity types to image file paths
  const amenityImages = {
    Toilets: toiletIcon,
    Barbecues: barbecueIcon,
    'Bicycle Rails': bicycleRailsIcon,
    'Litter Bin': binIcon,
    'Drinking Fountain': drinkingFountainIcon,
    'Picnic Setting': picnicSettingIcon,
    Playgrounds: playgroundIcon,
    Seat: seatIcon,
  };

  // Reset routeFitted state when new directions are provided
  useEffect(() => {
    if (directions) {
      setRouteFitted(false);  // Reset when new directions are provided
    }
  }, [directions]);

  // Effect to adjust map zoom and fit all amenities in view
  useEffect(() => {
    if (mapRef.current && amenities) {
      const map = mapRef.current.getMap();
      const bounds = new mapboxgl.LngLatBounds();

      // Add park location to bounds
      bounds.extend([parkCoordinates.longitude, parkCoordinates.latitude]);

      // Add all amenities to bounds
      Object.keys(amenities).forEach((amenityType) => {
        amenities[amenityType].forEach((amenity) => {
          bounds.extend([amenity.lon, amenity.lat]);
        });
      });

      // Fit map to show all amenities and the park
      if (!bounds.isEmpty()) {
        map.fitBounds(bounds, { padding: 50, maxZoom: 18, duration: 1000 });
      }
    }
  }, [amenities, parkCoordinates]);

  // Draw route on the map if directions are provided
  useEffect(() => {
    if (mapRef.current && directions?.coordinates && !routeFitted) {
      const map = mapRef.current.getMap();

      // Define a function to fit the map to the route's bounds
      const fitRouteBounds = () => {
        const routeGeoJSON = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: directions.coordinates,  // Ensure directions.coordinates is used
          },
        };

        // If the route source already exists, update the data
        if (map.getSource('route')) {
          map.getSource('route').setData(routeGeoJSON);
        } else {
          // Otherwise, add a new source and layer for the route
          map.addSource('route', {
            type: 'geojson',
            data: routeGeoJSON,
          });

          map.addLayer({
            id: 'route',
            type: 'line',
            source: 'route',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#3887be',
              'line-width': 5,
            },
          });
        }

        // Log the coordinates to verify they are correct
        console.log('Route Coordinates:', directions.coordinates);

        // Create a bounds object and extend it to include all route coordinates
        const bounds = new mapboxgl.LngLatBounds();
        directions.coordinates.forEach((coord) => {
          bounds.extend(coord);  // Extend the bounds to include each coordinate
        });

        // Ensure the bounds are valid and not empty
        if (!bounds.isEmpty()) {
          console.log('Fitting bounds to route...');
          map.fitBounds(bounds, {
            padding: 50,  // Padding around the route
            maxZoom: 16,  // Max zoom level to prevent too close zooming
            duration: 1000,  // Duration of the animation
          });

          // Set the route as fitted
          setRouteFitted(true);
        } else {
          console.log('Bounds are empty, cannot fit route.');
        }
      };

      // Check if the map is already loaded
      if (map.isStyleLoaded()) {
        fitRouteBounds();  // Fit the route if the map is already loaded
      } else {
        // Wait for the map to load before fitting bounds
        map.on('load', () => {
          fitRouteBounds();
        });
      }
    }
  }, [directions, routeFitted]);

  if (!parkCoordinates) {
    return <div>Loading Map...</div>;
  }

  return (
    <Map
      ref={mapRef}
      initialViewState={{
        longitude: parkCoordinates.longitude,
        latitude: parkCoordinates.latitude,
        zoom: 12,
      }}
      style={{ width: '100%', height: '500px' }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxAccessToken="pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q"
    >
      {/* Marker for the park location */}
      <Marker longitude={parkCoordinates.longitude} latitude={parkCoordinates.latitude}>
        <img
          src={currentLocationIcon}
          alt="Park Location"
          style={{ width: '40px', height: '40px' }}
        />
      </Marker>

      {/* Plot amenities on the map */}
      {Object.keys(amenities)
        .filter((amenityType) => amenityImages[amenityType])
        .map((amenityType) =>
          amenities[amenityType].map((amenity) => (
            <Marker key={amenity.id} longitude={amenity.lon} latitude={amenity.lat}>
              <img
                src={amenityImages[amenityType]}
                alt={amenityType}
                style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                onClick={() => onAmenityClick(amenity)}
              />
            </Marker>
          ))
        )}

      {/* Add navigation control for zoom and pan */}
      <NavigationControl position="top-left" />
    </Map>
  );
};

export default ExploreMap;
