import React from 'react';
import { Box, Typography, Card, CardContent, CardActions, Button } from '@mui/material';
import NavigateIcon from '@mui/icons-material/Navigation'; 
import NatureIcon from '@mui/icons-material/Nature';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

// Import the correct icons for the amenities
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import PicnicIcon from '@mui/icons-material/OutdoorGrill'; // Picnic Setting icon
import WcIcon from '@mui/icons-material/Wc';
import swingImage from '../assets/images/swing.png';
import slideImage from '../assets/images/slide.png';
import ShadeIcon from '@mui/icons-material/FilterDrama'; // Shade icon
import SeatIcon from '@mui/icons-material/EventSeat'; // Seat icon
import PeopleIcon from '@mui/icons-material/People';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// Amenity icon mapping
const amenityIcons = {
  'Drinking Fountain': <LocalDrinkIcon style={{ color: '#1CB8FC' }} />,
  'Bicycle rails': <DirectionsBikeIcon style={{ color: '#FF9800' }} />,
  'Picnic Setting': <PicnicIcon style={{ color: '#8BC34A' }} />,
  'PicnicSetting': <PicnicIcon style={{ color: '#8BC34A' }} />,
  'Playground': <ChildFriendlyIcon style={{ color: '#FFC107' }} />,
  'Toilet': <WcIcon style={{ color: '#3F51B5' }} />,
  'Swing': <img src={swingImage} alt="Swing" style={{ width: '24px', height: '24px' }} />,
  'Slide': <img src={slideImage} alt="Slide" style={{ width: '24px', height: '24px' }} />,
  'Shade': <ShadeIcon style={{ color: '#11A022' }} />,
  'Seat': <SeatIcon style={{ color: '#795548' }} />, // Seat icon
  'default': <HelpOutlineIcon style={{ color: '#9E9E9E' }} /> // Use the correct icon here
};

// Icon mapping for space categories
const iconMap = {
  'Parks and gardens': <NatureIcon style={{ color: '#388E3C' }} />,
  'Sportsfields and organised recreation': <SportsBasketballIcon style={{ color: '#FF5722' }} />,
  'Playgrounds': <ChildFriendlyIcon style={{ color: '#FFC107' }} />
};

const SearchResults = ({ greenSpaces, onSelect }) => {
  return (
    <Box p={2} style={{ maxHeight: '100%', overflow: 'auto' }}>
      {greenSpaces.length > 0 ? (
        greenSpaces.map((space) => (
          <Card
            key={space.id}
            style={{
              marginBottom: '16px',
              borderRadius: '12px',
              backgroundColor: '#E8F5E9',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap={2}>
                  <Box>{iconMap[space.category]}</Box>
                  <Box>
                    <Typography variant="h6" style={{ color: '#2E7D32' }}>{space.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {space.category}
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {space.distance?.toFixed(2)} km away
                    </Typography>
                    <Typography variant="body2" color="textPrimary" component="div">
                      Amenities: {space.matchedAmenities && space.matchedAmenities.length > 0 
                        ? space.matchedAmenities.map(amenity => {
                            const Icon = amenityIcons[amenity] || amenityIcons['default'];
                            return (
                              <Box key={amenity} display="flex" alignItems="center" gap={1}>
                                {Icon} {amenity}
                              </Box>
                            );
                          })
                        : 'No amenity has been selected'}
                    </Typography>
                    <Typography variant="body2" sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px'
                    }}>
                      <PeopleIcon sx={{
                        color: space.crowding_level === 'High' ? 'red' : space.crowding_level === 'Medium' ? 'orange' : 'green'
                      }} />
                      <span style={{ color: 'black', fontWeight: 'normal' }}>Possibility of being crowded:</span>
                      <Box component="span" sx={{
                        fontWeight: 'bold',
                        color: space.crowding_level === 'High' ? 'red' : space.crowding_level === 'Medium' ? 'orange' : 'green'
                      }}>
                        {space.crowding_level}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
                <CardActions>
                  <Button
                    startIcon={<NavigateIcon />}
                    size="large"
                    style={{
                      color: '#ffffff',
                      backgroundColor: '#388E3C',
                      padding: '8px 16px',
                      fontWeight: 'bold',
                      borderRadius: '8px'
                    }}
                    onClick={() => onSelect(space)}
                  >
                    Navigate
                  </Button>
                </CardActions>
              </Box>
            </CardContent>
          </Card>
        ))
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          p={3}
          textAlign="center"
          bgcolor="#f0f4f7"
          borderRadius="12px"
        >
          <SentimentDissatisfiedIcon style={{ fontSize: '80px', color: '#a0a0a0' }} />
          <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
            No green spaces found.
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
            Try adjusting your search criteria or location and try again.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SearchResults;
