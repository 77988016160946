import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/logo2.png';
import Typography from '@mui/material/Typography';


// Navbar styles with updated new colors
const NavbarContainer = styled('nav')({
  width: '100vw', /* Full viewport width */
  height: '70px',
  backgroundColor: '#417446', // Fern Green
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 40px',
  position: 'fixed',
  top: 0,
  zIndex: 10,
});

// Logo container
const Logo = styled('div')({
  fontSize: '24px',
  fontWeight: 'bold',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  img: {
    height: '70px',
    marginRight: '10px',
  },
});

// Navigation links container
const NavLinks = styled('ul')({
  listStyle: 'none',
  display: 'flex',
  gap: '30px',
  padding: 0,
  margin: 0,
});

const NavLinkItem = styled('li')({
  color: '#e6deb8', // Dutch White for link text
  fontSize: '18px',
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline',
    color: '#c9c088', // Sage for hover effect
  },
  
  '&.active': {
    color: '#598f57', // Middle Green for active link
    fontWeight: 'bold',
  },
});

// Scroll back to top button
function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fab
      onClick={handleClick}
      role="presentation"
      sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: '#417446' }} // Fern Green for Scroll to top button
    >
      {children}
    </Fab>
  );
}

// Main application with the navbar at the top
export default function AppBarComponent(props) {
  const { content } = props;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh' }}>
      <CssBaseline />
      
      {/* Navbar */}
      <NavbarContainer>
        <Logo>
          <img src={logo} alt="Green Finder Logo" />
          <Typography variant="h5" sx={{ color: '#e6deb8', fontWeight: 'bold' }}> {/* Change color here */}
            GreenFinder
          </Typography>
        </Logo>
        <NavLinks>
          <NavLinkItem>
            <NavLink 
              to="/LandingPage" 
              style={{ color: 'inherit', textDecoration: 'none' , textstyle:'bold' }} 
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Home
            </NavLink>
          </NavLinkItem>
          <NavLinkItem>
            <NavLink 
              to="/why-green-spaces" 
              style={{ color: 'inherit', textDecoration: 'none' }} 
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Why Green Spaces?
            </NavLink>
          </NavLinkItem>
          <NavLinkItem>
            <NavLink 
              to="/nearby-green-spaces" 
              style={{ color: 'inherit', textDecoration: 'none' }} 
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Find Nearby
            </NavLink>
          </NavLinkItem>
          <NavLinkItem style={{ pointerEvents: 'none', opacity: 0.5 }}>
          <NavLink 
            to="/go-green" 
            style={{ color: 'inherit', textDecoration: 'none' }} 
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Go Green
          </NavLink>
        </NavLinkItem>

        </NavLinks>
      </NavbarContainer>

      <Toolbar id="back-to-top-anchor" />
      
      {/* Main content */}
      <Box component="main" sx={{ flexGrow: 1, padding: 0, width: '100vw', height: '100vh' }}>
        {content}
      </Box>

      {/* Scroll to Top Button */}
      <ScrollTop {...props}>
        <KeyboardArrowUpIcon />
      </ScrollTop>
    </Box>
  );
}
