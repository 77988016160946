import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import homepageImage from '../assets/images/homepage.jpg';
import customerExperienceImage from '../assets/images/customer-experience.png';
import skyscraperImage from '../assets/images/skyscraper.png';
import { useNavigate } from 'react-router-dom';

// Keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideInUp = keyframes`
  from { transform: translateY(100px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

// Styled components for layout and sections
const Container = styled.div`
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f5f4e2; // Variant white
  width: 100vw;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const HeroSection = styled.section`
  width: 100vw;
  height: 100vh;
  background: url(${homepageImage}) center/cover no-repeat, rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  animation: ${fadeIn} 2s ease-in-out;
`;

const HeroTitle = styled.h2`
  font-size: 56px;
  font-weight: bold;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
`;

const HeroSubtitle = styled.p`
  font-size: 22px;
  margin-bottom: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

const LearnMoreButton = styled.button`
  padding: 15px 40px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: #668d2e;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  &:hover {
    background-color: #598f57;
    transform: scale(1.05); // Slight scale-up on hover
  }
`;

const SectionTitle = styled.h3`
  font-size: 36px;
  margin-bottom: 20px;
  color: #417446;
  text-align: center;
  animation: ${slideInUp} 1s ease;
`;

// Problem Section
const ProblemSection = styled.section`
  width: 100vw;
  padding: 60px 20px;
  background-color: #e6deb8; // Dutch White
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation: ${fadeIn} 2s ease forwards;
  animation-delay: 0.5s; // Delayed to appear smoothly after scroll
`;

const ProblemText = styled.p`
  max-width: 800px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.6;
`;

// Selling Point Section
const SellingPointSection = styled.section`
  width: 100vw;
  padding: 60px 20px;
  background-color: #f0ebd4;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 30px;
  width: 85%;
  max-width: 1200px;
  margin-top: 30px;
`;

const FeatureCard = styled.div`
  background-color: #b8b193;
  padding: 20px;
  border-radius: 50%;  /* Makes the card circular */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;  /* Fixed width */
  height: 300px; /* Fixed height */
  &:hover {
    transform: scale(1.05);
    color: #417446;
  }
`;

const FeatureIcon = styled.div`
  font-size: 36px;
  margin-bottom: 10px;
  color: #598f57;
`;

const FeatureText = styled.p`
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: #454237;
`;

const ListSection = styled.section`
  width: 100vw;
  padding: 20px 20px;
  background-color: #f0ebd4;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  max-width: 800px;
`;

const ListItem = styled.li`
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Icon = styled.span`
  font-size: 30px;
  margin-right: 15px;
`;

const ListText = styled.span`
  line-height: 1.6;
`;

const BenefitsSection = styled.section`
  width: 100vw;
  padding: 50px 20px;
  background-color: #f0ebd4;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BenefitGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 30px;
  width: 80%;
  max-width: 1200px;
  animation: ${slideInUp} 1s ease;
`;

const BenefitCard = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: scale(1.05);
    color: #417446;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);  /* Enhanced hover effect */
  }
`;

const NumberContainer = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #598f57;
  background: linear-gradient(145deg, #e6deb8, #f0ebd4);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 150px;
  min-width: 150px;
  margin: 0 auto; /* Centering horizontally */
`;

const CountupContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: 26px;
`;

const CallToActionSection = styled.section`
  padding: 50px;
  background-color: #598f57;
  color: white;
  text-align: center;
`;

const CallToActionButton = styled.button`
  padding: 15px 40px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  background-color: #f0ebd4;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: #ede7cd;
  }
`;

const Footer = styled.footer`
  padding: 30px;
  background-color: #417446;
  color: white;
  text-align: center;
`;

function LandingPage() {
  const [startCount, setStartCount] = useState(false);
  const navigate = useNavigate(); // Correct initialization
  const problemSectionRef = useRef(null);
  const { inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setStartCount(true);
    }
  }, [inView]);

  const scrollToProblemSection = () => {
    problemSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleAssessmentClick = () => {
    navigate('/assessment'); // <-- Redirect to the assessment page
  };

  return (
    <Container>
      {/* Hero Section */}
      <HeroSection>
        <HeroTitle>The Perfect Green Spaces Guide for Busy Parents</HeroTitle>
        <HeroSubtitle>
          Discover safe, peaceful, and family-friendly parks in the City of Melbourne.
        </HeroSubtitle>
        <LearnMoreButton onClick={scrollToProblemSection}>Learn More</LearnMoreButton>
      </HeroSection>

      {/* Problem Section */}
      <ProblemSection ref={problemSectionRef}>
        <SectionTitle>Ever Wondered Why It's Still Hard to Find a Peaceful Green Space?</SectionTitle>
        <ProblemText>
          Despite the City of Melbourne having many green spaces, finding a peaceful and uncrowded spot can be challenging. Overcrowding, rapid urban development, and busy schedules make it difficult for families to enjoy a moment of tranquility in nature.
        </ProblemText>
      </ProblemSection>

      {/* Selling Point Section */}
      <SellingPointSection>
        <SectionTitle>Why you need GreenFinder</SectionTitle>
        <FeatureGrid>
          <FeatureCard>
            <FeatureIcon>🕒🏃🏻‍♀️</FeatureIcon>
            <FeatureText>Find the perfect time to visit—whether you prefer a bustling atmosphere or a quiet escape, the choice is yours.</FeatureText>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon>👧🏻👦🏼</FeatureIcon>
            <FeatureText>Easily discover nearby child-friendly parks with playgrounds and safety features, just around the corner.</FeatureText>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon>📅🌤️</FeatureIcon>
            <FeatureText>Plan your visits for the optimal dates and times, ensuring a smooth experience even when things don’t go as planned.</FeatureText>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon>🐨🌿</FeatureIcon>
            <FeatureText>Discover the unique flora and fauna in each park and learn how you can support local biodiversity. </FeatureText>
          </FeatureCard>
        </FeatureGrid>
      </SellingPointSection>

      {/* How Green Spaces Help Section */}
      <ListSection>
        <SectionTitle>How Green Spaces Help the City and Its Residents</SectionTitle>
        <List>
          <ListItem>
            <Icon>🌳</Icon>
            <ListText>Healthier Living: Visits to green spaces reduce stress, improve health, and lower congestion in urban areas.</ListText>
          </ListItem>
          <ListItem>
            <Icon>🚴‍♀️</Icon>
            <ListText>Sustainable Mobility: Walking or biking to parks reduces your carbon footprint, helping create cleaner air.</ListText>
          </ListItem>
          <ListItem>
            <Icon>🌿</Icon>
            <ListText>Environmental Benefits: Green spaces foster biodiversity, enhance safety, and reduce urban heat.</ListText>
          </ListItem>
          <ListItem>
            <Icon>☀️</Icon>
            <ListText>Cooler Cities: Green spaces absorb heat, making cities more livable, especially during summer months.</ListText>
          </ListItem>
        </List>
      </ListSection>

      {/* Benefits Section */}
      <BenefitsSection>
    <BenefitGrid>
      <BenefitCard>
        <NumberContainer>
          50%
        </NumberContainer>
        <p>Reduction in Carbon Footprint by walking or biking to parks.</p>
      </BenefitCard>

      <BenefitCard>
        <NumberContainer>
          20%
        </NumberContainer>
        <p>Energy savings by spending time outdoors.</p>
      </BenefitCard>

      <BenefitCard>
        <NumberContainer>
          7%
        </NumberContainer>
        <p>Boost in local economy by increasing foot traffic.</p>
      </BenefitCard>

      <BenefitCard>
        <NumberContainer>
          15%
        </NumberContainer>
        <p>Increase in community engagement through green spaces.</p>
      </BenefitCard>
    </BenefitGrid>
  </BenefitsSection>

      {/* Call to Action Section */}
      <CallToActionSection>
        <h3>Are You Ready to Help Melbourne Thrive?</h3>
        <p>Assess your green lifestyle and discover how you can contribute to a sustainable future.</p>
        <CallToActionButton onClick={handleAssessmentClick}>
    Assess My Green Lifestyle
</CallToActionButton>


      </CallToActionSection>

      {/* Footer */}
      <Footer>
        <p>&copy; 2024 GreenFinder. All rights reserved.</p>
      </Footer>
    </Container>
  );
}

export default LandingPage;
