import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; 
import { Container, Box, Grid, Typography, Button, Paper } from '@mui/material';
import LineChart from './LineChart';






// ParkInfoDetailPage Component
const ParkInfoDetailPage = () => {
  const location = useLocation(); 
  const { park, userCoordinates } = location.state || {};  // Now getting userCoordinates from location.state
  const navigate = useNavigate(); 

  // Handle the case when park data is not available
  if (!park) {
    return (
      <Container maxWidth="lg">
        <Box p={4} textAlign="center">
          <Typography variant="h4" color="error">
            No Park Data Available
          </Typography>
        </Box>
      </Container>
    );
  }

  // // Mock user coordinates for navigation (replace with real data if available)
  // const userCoordinates = {
  //   latitude: -37.8136,
  //   longitude: 144.9631,
  // };

  // Navigate to different pages
  const handleNavigateClick = () => {
    navigate('/navigate', { state: { park, userCoordinates } });
  };

  const handleExploreClick = () => {
    navigate('/explore-park', { state: { park } });
  };

  const handleBackClick = () => {
    sessionStorage.setItem('backNavigation', 'true');
    navigate(-1);
  };

  return (
    <Container maxWidth="lg">
      {/* Back Button */}
      <Box p={2} textAlign="left">
        <Button
          variant="contained"
          color="success"
          size="large"
          sx={{ borderRadius: '8px', padding: '10px 20px', fontWeight: 'bold' }}
          onClick={handleBackClick}
        >
          Back
        </Button>
      </Box>

      {/* Park Name */}
      <Box p={4} textAlign="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            color: '#2E7D32',
            letterSpacing: '0.5px',
            textTransform: 'capitalize',
            mb: 2,
          }}
        >
          Learn more about {park.name}
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {/* Left Column: Picture of Park */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              height: '300px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#e0f2f1',
              borderRadius: '12px',
            }}
          >
            <Typography variant="h6" sx={{ color: '#00796B' }}>
              Park Image Placeholder
            </Typography>
          </Paper>

          {/* Buttons */}
          <Box display="flex" justifyContent="space-between" mt={4}>
            <Button
              variant="contained"
              color="success"
              size="large"
              sx={{
                borderRadius: '8px',
                padding: '10px 20px',
                fontWeight: 'bold',
              }}
              onClick={handleExploreClick}
            >
              Explore the Park
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                borderRadius: '8px',
                padding: '10px 20px',
                fontWeight: 'bold',
              }}
              onClick={handleNavigateClick}
            >
              Navigate
            </Button>
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          {/* General Data */}
          <Box mb={2}>
            <Paper elevation={3} sx={{ padding: '16px', borderRadius: '12px' }}>
              <Typography variant="h6" sx={{ color: '#2E7D32', fontWeight: 'bold' }}>
                General Data
              </Typography>
              <Typography variant="body1" sx={{ color: '#00796B' }}>
                {park.category}
              </Typography>
              <Typography variant="body1" sx={{ color: '#00796B' }}>
                {park.distance?.toFixed(2)} km away
              </Typography>
            </Paper>
          </Box>

          {/* Crowd Insights - 添加 LineChart */}
          <Box mb={2}>
            <Paper elevation={3} sx={{ padding: '16px', borderRadius: '12px' }}>
              <Typography variant="h6" sx={{ color: '#2E7D32', fontWeight: 'bold' }}>
                Crowd Insights
              </Typography>
              <LineChart /> {/* 这里插入生成的折线图 */}
            </Paper>
          </Box>

          {/* More Like This */}
          <Box mb={2}>
            <Paper elevation={3} sx={{ padding: '16px', borderRadius: '12px' }}>
              <Typography variant="h6" sx={{ color: '#2E7D32', fontWeight: 'bold' }}>
                More Parks Like This
              </Typography>
              <Typography variant="body1" sx={{ color: '#00796B' }}>
                Explore other nearby parks similar to {park.name}.
              </Typography>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ParkInfoDetailPage;
